import { ADMIN_URL, GATEWAY_URL } from "../js/defines";
import { createContext, useContext, useEffect, useState } from "react";

import Cookies from "universal-cookie";
import dayjs from "dayjs";

const cookies = new Cookies();
const AuthContext = createContext();

export default function AuthProvider({ children }) {
  const getCookie = (key) => cookies.get(key) || null;

  const [userState, setUserState] = useState(getCookie("user"));
  const [userId, setUserId] = useState(getCookie("userId"));
  const [refreshToken, setRefreshToken] = useState(getCookie("refreshToken"));

  const clearCookies = () => {
    ["user", "userId", "refreshToken"].forEach((key) => cookies.remove(key, { path: "/" }));
  };

  const refreshCredentials = async () => {
    if (!refreshToken || !userId) {
      console.warn("No refresh token found. User might be logged out.");
      return;
    }

    try {
      const response = await fetch(`${GATEWAY_URL}${ADMIN_URL}/api/Auth/refresh-token`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ refreshToken, userId }),
      });

      if (!response.ok) throw new Error("Session expired");

      const updatedUser = await response.json();
      updateCookies(updatedUser);
      setUserState(updatedUser);
    } catch (error) {
      alert("Session expired. Please log in again.");
      clearCookies();
      window.location.reload();
    }
  };

  const updateCookies = (user) => {
    if (!user) return;
    cookies.set("user", user, { path: "/", expires: dayjs(user.expiresIn).toDate() });
    cookies.set("refreshToken", user.refreshToken, { path: "/", expires: dayjs(user.refreshToken_expiresIn).toDate() });
    cookies.set("userId", user.userState?.id, { path: "/", expires: dayjs(user.refreshToken_expiresIn).toDate() });
  };

  useEffect(() => {
    if (userState) {
      updateCookies(userState);
    } else if (refreshToken && userId) {
      refreshCredentials();
    } else {
      clearCookies();
    }
  }, [userState]);

  return (
    <AuthContext.Provider value={{ userState, setUserState }}>
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => useContext(AuthContext);
