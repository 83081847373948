import { useEffect, useState } from "react";

import DataProvider from "../providers/DataProvider";
import LoginView from "./loginView/LoginView";
import MainView from "./mainView/MainView";
import SignalRProvider from "../providers/SignalRProvider";
import { useAuth } from "../providers/AuthProvider";

import { BrowserRouter as Router, Route, Routes, useParams } from "react-router-dom";

export default function Main() {
  const { userState } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Track authentication state

  useEffect(() => {
    if (userState?.token) {
      setIsAuthenticated(true); // User is authenticated
    } else {
      setIsAuthenticated(false); // User is not authenticated
    }
  }, [userState]);
  
  if (isAuthenticated == null) {
    return <></>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={
          isAuthenticated && userState? (
            <SignalRProvider userState={userState} bearerToken={userState?.token}>
              <DataProvider userState={userState}>
                <MainView />
              </DataProvider>
            </SignalRProvider>
          ) : (
              <>
            <LoginView />
            </>
          )
        } />
      </Routes>
    </Router>
  );
}
