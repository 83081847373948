// react
import React, { useState, useEffect, useRef } from "react";

// openlayers
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Cluster from "ol/source/Cluster";
import OSM from "ol/source/OSM";
import { fromLonLat, toLonLat } from "ol/proj.js";
import { Control, ScaleLine, defaults as defaultControls } from "ol/control.js";
import "./BasicMapExport.css";
import { Feature } from "ol";
import { Point } from "ol/geom";
import TileWMS from "ol/source/TileWMS.js";
import northPointer from "../../assets/north_pointer.svg";
import poweredByWiv from "../../assets/WIV_poweredby_Logo.png";
import { useMap } from "../../context/MapProvider";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import { scales, WIV_LOGO } from "../../js/defines";
import { setMapScale } from 'ol-ext/geom/sphere';
import * as htmlToImage from 'html-to-image';
import XYZ from "ol/source/XYZ";
import { createEmpty, extend } from "ol/extent";
import { easeOut } from "ol/easing";
import { useServerData } from "../../providers/DataProvider";
import GeoJSON from 'ol/format/GeoJSON.js';
import { useAuth } from "../../providers/AuthProvider";
import { evaluateGeometryStyle, evaluateMarkerStyle } from "./mapStyles";

function BasicMapExport(props) {
  // set intial state
  const mapElement = useRef();
  const mapRef = useRef();
  const baseLayerRef = useRef();

  const posLayerRef = useRef();
  const vecLayerRef = useRef();

  const oemLabelRef = useRef();
  const northArrowRef = useRef();
  const mapInfoRef = useRef();
  const scaleLineRef = useRef();

  const { userState } = useAuth();

  const [currentMapPos, setCurrentMapPos] = useState([]);

  const {
    currentCompany,
    userSettings,
    cfgs,
    styles,
    selectedStyles,
    mapConfigs,
    selectedPois,
    selectedBlobs
  } = useServerData();

  const [map, setMap] = useState();

  const [baseSrc, setBaseSrc] = useState(
    new XYZ({
      url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
      crossOrigin:"anonymous",
    })
  );


  //NEW SYSTEM CODE - DONE
  function drawPoisPosition() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    for (let e of Object.entries(selectedPois)) {
      let uniqueKey = e[0];
      let entries = e[1];
      let config = cfgs.find((config) => config.uniqueKey == uniqueKey);

      let posLayer;
      for (let layer of layers) {
        if (layer.get("layerId") == uniqueKey) {
          posLayer = layer;
          posLayerRef.current = layer;
          continue;
        }
      }

      if (posLayer == null) {
        const vectorSource = new VectorSource();
        const clusterSource = new Cluster({
          distance: 30,
          minDistance: 20,
          source: vectorSource,
        });
        posLayer = new VectorLayer({
          source: clusterSource,
          opacity: 1,  //WHEN SOME GEOMETRY IS BEEN EDITED? ///TODO
          layerId: uniqueKey,
          zIndex: 999,
        });
        posLayerRef.current = posLayer;
        if (map != undefined) {
          mapRef.current.addLayer(posLayer);
        }
      }

      posLayerRef.current.getSource().setDistance(!mapConfigs[uniqueKey]?.noCluster ? 30 : 1);
      posLayerRef.current.getSource().setMinDistance(!mapConfigs[uniqueKey]?.noCluster ? 20 : 1);

      let features = [];
      let locationCol = config?.columns?.find((i) => i.fieldType == "Location");
      for (let entry of entries) {
        if (mapConfigs[uniqueKey]?.showIcons && locationCol) {
          let feature = new Feature({
            geometry: new Point([
              entry ? entry[locationCol.key]?.long : 0,
              entry ? entry[locationCol.key]?.lat : 0,
            ]).transform("EPSG:4326", "EPSG:3857"),
            layerId: uniqueKey,
            tableIcon: config?.tableIcon,
            tableIconBgColor: config?.tableIconBgColor,
            mapLabelTemplate: config?.mapLabelTemplate,
            guid: entry._id,
          });
          feature.setProperties(entry);
          features.push(feature);
        }
      }

      if (posLayer != undefined) {
        posLayerRef.current.setStyle((features, resolution) => evaluateMarkerStyle(features, resolution, styles, selectedStyles[uniqueKey], mapConfigs));
      }

      if (posLayerRef.current) {
        posLayerRef.current.getSource().getSource().clear();
        posLayerRef.current.getSource().getSource().addFeatures(features);
      }
    }
  }

  function drawPoiGeometries() {
    if (mapRef.current == null) return;
    let layers = mapRef.current.getAllLayers();

    for (let e of Object.entries(selectedPois)) {
      let uniqueKey = e[0];
      let entries = e[1];
      let config = cfgs.find((config) => config.uniqueKey == uniqueKey);
      
      let vectorOverlayKey = "map";
      if(config) {
        let vectorOverlay = config.columns?.find((row) => row.fieldType == "Map");
        if(vectorOverlay != null && vectorOverlay.columnKey != null) vectorOverlayKey = vectorOverlay.columnKey;
      }

      let vecLayer;

      for (let layer of layers) {
        if (layer.get("layerId") == uniqueKey + "_" + vectorOverlayKey) {
          vecLayer = layer;
          vecLayerRef.current = layer;
          continue;
        }
      }

      if (vecLayer == null) {
        const vectorSource = new VectorSource();
        vecLayer = new VectorLayer({
          source: vectorSource,
          opacity: 1,
          layerId: uniqueKey + "_" + vectorOverlayKey,
          zIndex: 998
        });
        vecLayerRef.current = vecLayer;
        if (map != undefined) {
          mapRef.current.addLayer(vecLayer);
        }
      }

      let features = [];
      if (entries != null) {
        entries.forEach((entry, idx) => {
          if (mapConfigs[uniqueKey] && mapConfigs[uniqueKey].showLayer) {
            let geojson = new GeoJSON();

            let innerFeatures = [];
            if(entry[vectorOverlayKey]) {
              let geoJsonString = null;
              try {
                geoJsonString = JSON.parse(entry[vectorOverlayKey])
              } catch(e) {}

              if(geoJsonString != null) {
                innerFeatures = geojson.readFeatures(geoJsonString, {
                  dataProjection: "EPSG:4326",
                  featureProjection: "EPSG:3857",
                });
              }
            }

            innerFeatures.forEach((feature) => {
              feature.setProperties(entry);
              feature.setProperties({
                layerId: uniqueKey + "_" + vectorOverlayKey,
                blobGuid: entry.id,
              });
            });

            features = features.concat(innerFeatures);
          }
        });
      }

      if (vecLayerRef != undefined) {
        vecLayerRef.current.setStyle((features, resolution) => evaluateGeometryStyle(features, resolution, styles, selectedStyles[uniqueKey], mapConfigs));
      }

      if (vecLayerRef.current) {
        vecLayerRef.current.getSource().clear();
        vecLayerRef.current.getSource().addFeatures(features);
      }
    }
  }


  //#region  Export 
  
  function getSelectedPois() {
    return selectedPois;
  }

  function getSelectedBlobs() {
    return selectedBlobs;
  }

  function getSelectedStyle() {
    return selectedStyles;
  }

  function getMapConfig() {
    return mapConfigs;
  }

  function getExportMapPos() {
    if(mapRef.current) {
      const pos = toLonLat(mapRef.current.getView().getCenter());
      let posObj = {
        pos: pos,
        zoom: mapRef.current.getView().getZoom(),
        bounds: mapRef.current.getView().calculateExtent(mapRef.current.getSize())
      };
      return posObj;
    }
  }

  function exportMapAsImage(dim, resolution, scale, orientation) {
    const width = parseInt(((orientation == "portrait" ? dim[0] : dim[1]) * resolution) / 25.4);
    const height = parseInt(((orientation == "portrait" ? dim[1] : dim[0]) * resolution) / 25.4);

    return new Promise((resolve, reject) => {
      let desiredHeight = height;
      let desiredWidth = width;

      const mapCanvas = document.createElement('canvas');
      mapCanvas.width = desiredWidth;
      mapCanvas.height = desiredHeight;
      const mapContext = mapCanvas.getContext('2d');
      mapContext.fillStyle = '#000000'; // Fill with white background if needed
      mapContext.fillRect(0, 0, desiredWidth, desiredHeight);

      function filter(node) {
        const exclusionClasses = ['ol-zoom'];
        return !exclusionClasses.some((classname) => node.classList?.contains(classname));
      };

      htmlToImage.toCanvas(mapElement.current, {filter: filter}).then((exportCanvas) => {
        const opacity = exportCanvas.style.opacity;
        mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
        mapContext.drawImage(exportCanvas, 0, 0, desiredWidth, desiredHeight);
        mapContext.globalAlpha = 1;
        mapContext.setTransform(1, 0, 0, 1, 0, 0);

        const dataUrl = mapCanvas.toDataURL();
        resolve(dataUrl);
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
        resolve(null);
      });
    });
  }

  const { setExportFunction, setGetSelectedPoisFunction, setGetSelectedBlobsFunction, setGetSelectedStyleFunction, setGetMapConfigFunction, setGetExportMapPosFunction } = useMap();
  useEffect(() => {
    setExportFunction(exportMapAsImage);
    setGetSelectedPoisFunction(getSelectedPois);
    setGetSelectedBlobsFunction(getSelectedBlobs);
    setGetSelectedStyleFunction(getSelectedStyle);
    setGetMapConfigFunction(getMapConfig);
    setGetExportMapPosFunction(getExportMapPos);
  }, [selectedPois, selectedBlobs, selectedStyles, mapConfigs]);

  //#endregion

  useEffect(() => {
    if(mapRef.current) {
      if(props.mapScale != "custom") {
        mapRef.current.getView().set('moveInitiatedProgrammatically', true);
        setMapScale(mapRef.current, props.mapScale);
      }

      if(props.mapScale == "fit") {
        var poiExtent;
        var geomExtent;
        if(mapRef.current) {
          let layers = mapRef.current.getAllLayers();
          layers.map((layer) => {
            if(layer.get("layerId")) {
              //POI
              if(layer.get("zIndex") == 999 && layer.getSource().getExtent() != undefined) {
                if(poiExtent == undefined) poiExtent = createEmpty();
                extend(poiExtent, layer.getSource().getExtent())
              }
              //Geoms
              if(layer.get("zIndex") == 998 && layer.getSource().getExtent() != undefined) {
                if(geomExtent == undefined) geomExtent = createEmpty();
                extend(geomExtent, layer.getSource().getExtent())
              }
            }
          });
        }
  
        let hasGeometries = geomExtent != null;
        let hasPoiIcons = poiExtent != null;
  
        if(hasPoiIcons && hasGeometries) {
          var extent = createEmpty();
          extend(extent, poiExtent);
          extend(extent, geomExtent);
  
          if(mapRef.current != null && selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20,
              easing: easeOut
            };
            if(extent != null && extent[0] != Infinity) mapRef.current.getView().fit(extent, animationOptions);
          }
        }
  
        if(hasPoiIcons && !hasGeometries) {
          if(mapRef.current != null && props.selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20,
              easing: easeOut
            };
            if(poiExtent != null && poiExtent[0] != Infinity) mapRef.current.getView().fit(poiExtent, animationOptions);
          }
        }
  
        if(!hasPoiIcons && hasGeometries) {
          if (mapRef.current != null && props.selectedPois != null) {
            var animationOptions = {
              duration: 750,
              padding: [100, 100, 100, 100],
              maxZoom: 20,
              easing: easeOut
            };
            if(geomExtent != null && geomExtent[0] != Infinity) mapRef.current.getView().fit(geomExtent, animationOptions);
          }
        }
      }
    }
  }, [props.mapScale, currentMapPos]);

  useEffect(() => {
    if(mapRef.current) {
      mapRef.current.getInteractions().forEach(function(interaction) {
        if (interaction instanceof MouseWheelZoom) {
          interaction.setActive(props.mapScale == "custom");
        }
      }, this);
    }

    document.getElementById("basicmapexport").onwheel = function() {
      if(props.mapScale != "custom") {
        props.onDisabledZoomScroll(true);
      }
    }
  }, [props.mapScale]);


  class OEMLabel extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      const img1 = document.createElement("img");
      img1.className = "ol-oem-label";
      img1.draggable = false;
      img1.src = poweredByWiv;

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-oem-label-control"
      mainDiv.className = "ol-control ol-oem-label ol-hidden";
      mainDiv.appendChild(img1);

      super({
        element: mainDiv,
        target: options.target,
      });
    }
  }

  class NorthArrow extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      const img1 = document.createElement("img");
      img1.className = "ol-north-arrow";
      img1.draggable = false;
      img1.src = northPointer;

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-north-arrow-control-id"
      mainDiv.className = "ol-control ol-north-arrow-control ol-hidden";
      mainDiv.appendChild(img1);

      super({
        element: mainDiv,
        target: options.target,
      });
    }


    setVisible(visible) {
      let mainDiv = document.getElementById("ol-north-arrow-control-id");
      if(!mainDiv) return;
      if(visible) {
        mainDiv.classList.remove("ol-hidden");
      } else {
        mainDiv.classList.add("ol-hidden");
      }
    }
  }

  class MapInfo extends Control {
    constructor(opt_options) {
      const options = opt_options || {};
      
      const fileName = document.createElement("p");
      fileName.id = "ol-info-filename"
      fileName.className = "ol-info-filename";
      fileName.innerHTML = "<b>Name: </b>" + props.fileName;

      const createdBy = document.createElement("p");
      createdBy.id = "ol-info-created-by"
      createdBy.className = "ol-info-created-by";
      createdBy.innerHTML = "<b>Erstellt durch: </b>" + userState?.userState?.fullname;

      const mapScale = document.createElement("p");
      mapScale.id = "ol-info-map-scale"
      mapScale.className = "ol-info-map-scale";
      mapScale.innerHTML = "<b>Maßstab: </b>" + scales[props.mapScale];

      const img1 = document.createElement("img");
      img1.className = "ol-info-company-icon";
      img1.draggable = false;
      img1.src = currentCompany?.companyLogo != null && currentCompany?.companyLogo != "" ? currentCompany?.companyLogo : WIV_LOGO;

      const imgDiv = document.createElement("div");
      imgDiv.id = "ol-info-company-icon-div"
      imgDiv.className = "ol-info-company-icon-div";
      imgDiv.appendChild(img1);

      const leftDiv = document.createElement("div");
      leftDiv.id = "ol-info-left"
      leftDiv.className = "ol-control ol-info-left";
      leftDiv.appendChild(createdBy);
      leftDiv.appendChild(mapScale);
      leftDiv.appendChild(imgDiv);

      const rightDiv = document.createElement("div");
      rightDiv.id = "ol-info-right"
      rightDiv.className = "ol-control ol-info-right";

      const mainDiv = document.createElement("div");
      mainDiv.id = "ol-info-control"
      mainDiv.className = "ol-control ol-info-control";
      mainDiv.appendChild(leftDiv);
      mainDiv.appendChild(rightDiv);

      super({
        element: mainDiv,
        target: options.target,
      });
    }

    setCompanyIconVisible(visible) {
      let mainDiv = document.getElementById("ol-north-arrow-control");
      if(!mainDiv) return;

      if(visible) {
        mainDiv.classList.remove("ol-hidden");
      } else {
        mainDiv.classList.add("ol-hidden");
      }
    }

    setLegendVisible(visible) {
      if(visible) {
        let rightDiv = document.getElementById("ol-info-right");
        let legend = document.getElementById("ol-info-legend");
        if(rightDiv && legend) {
          rightDiv.removeChild(legend);
        }

        const legendTab = document.createElement("div");
        legendTab.id = "ol-info-legend"
        legendTab.className = "ol-info-legend";

        const legendInfo = document.createElement("p");
        legendInfo.id = "ol-info-legend-info"
        legendInfo.className = "ol-info-legend-info";
        legendInfo.innerHTML = "<b>Legende: </b>";
        legendTab.appendChild(legendInfo);

        cfgs.map((config) => {
          let selectedStyle = selectedStyles[config.uniqueKey];
          let foundStyle = styles?.find((element) => element.id == selectedStyle);
          let visisbleOnMap = mapConfigs[config.uniqueKey] ? mapConfigs[config.uniqueKey].showIcons : false;

          if(selectedPois[config.uniqueKey] && selectedPois[config.uniqueKey].length > 0 && visisbleOnMap && !foundStyle) {
            const gridItemImg = document.createElement("img");
            gridItemImg.className = "ol-info-icon";
            gridItemImg.src = config?.tableIcon;
            gridItemImg.style = "width: 36px; padding: 1px; border-radius: 6px; background-color: " + config?.tableIconBgColor;

            const gridItemTxt = document.createElement("p");
            gridItemTxt.className = "ol-info-text";
            gridItemTxt.textContent = config.tableName;

            const gridItem = document.createElement("div");
            gridItem.id = "ol-info-legend-item"
            gridItem.className = "ol-info-legend-item";
            gridItem.appendChild(gridItemImg);
            gridItem.appendChild(gridItemTxt);
            legendTab.appendChild(gridItem);

            let rightDiv = document.getElementById("ol-info-right");
            if(rightDiv) {
              rightDiv.appendChild(legendTab);
            }
          }

          //Add GisStyle Legend for Icons
          if(selectedStyle) {
            if(foundStyle) {
              foundStyle.styleRules?.filter((i) => i.target == "marker")?.map((rule) => {
                if((rule.styleType == "stroke_color" || rule.styleType == "fill_color" || rule.styleType == "icon_url") && rule.legendText != "") {
                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.styleType == "stroke_color") {
                    gridItemImg.style = "border: 4px solid " + rule.value + "; height: 20px; width: 20px; border-radius: 100svh;";
                  } else if(rule.styleType == "fill_color") {
                    gridItemImg.style = "background-color: " + rule.value + "; height: 28px; width: 28px; border-radius: 100svh;";
                  } else {
                    const gridItemImgIcon = document.createElement("img");
                    gridItemImgIcon.src = rule.value != null && rule.value != "" && rule.value.includes("://") ? rule.value : "http://www.portal.wood-in-vision.com/runtime/" + rule.value;
                    gridItemImgIcon.id = "ol-info-legend-item-img"
                    gridItemImgIcon.style = "height: 28px; width: 28px;";
                    gridItemImg.appendChild(gridItemImgIcon);
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.legendText;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }

          //Add GisStyle Legend
          if(selectedStyle) {
            if(foundStyle) {
              foundStyle.styleRules?.filter((i) => i.target == "geometry")?.map((rule) => {
                if((rule.styleType == "stroke_color" || rule.styleType == "fill_color") && rule.legendText != null && rule.legendText != "") {
                  const gridItemImg = document.createElement("div");
                  gridItemImg.className = "ol-info-icon";
                  if(rule.styleType == "stroke_color") {
                    gridItemImg.style = "border: 4px solid " + rule.value + "; height: 20px; width: 20px; border-radius: 4px;";
                  } else {
                    gridItemImg.style = "background-color: " + rule.value + "; height: 28px; width: 28px; border-radius: 4px;";
                  }

                  const gridItemTxt = document.createElement("p");
                  gridItemTxt.className = "ol-info-text";
                  gridItemTxt.textContent = rule.legendText;

                  const gridItem = document.createElement("div");
                  gridItem.id = "ol-info-legend-item"
                  gridItem.className = "ol-info-legend-item";
                  gridItem.appendChild(gridItemImg);
                  gridItem.appendChild(gridItemTxt);
                  legendTab.appendChild(gridItem);

                  let rightDiv = document.getElementById("ol-info-right");
                  if(rightDiv) {
                    rightDiv.appendChild(legendTab);
                  }
                }
              })
            }
          }
        })
      } else {
        let rightDiv = document.getElementById("ol-info-right");
        let legend = document.getElementById("ol-info-legend");
        if(rightDiv && legend) {
          rightDiv.removeChild(legend);
        }
      }
    }

    setFileNameVisible(visible) {
      let leftDiv = document.getElementById("ol-info-left");
      if(!leftDiv) return;

      if(visible) {
        const fileName = document.createElement("p");
        fileName.id = "ol-info-filename"
        fileName.className = "ol-info-filename";
        fileName.innerHTML = "<b>Name: </b>" + props.fileName;
        leftDiv.insertBefore(fileName, leftDiv.firstChild);
      } else {
        let fileName = document.getElementById("ol-info-filename");
        if(fileName) {
          leftDiv.removeChild(fileName);
        }
      }
    }

    setFileName(fileName) {
      let fileNameP = document.getElementById("ol-info-filename");
      if(!fileNameP) return;
      fileNameP.innerHTML = "<b>Name: </b>" + fileName;
    }

    setMapScale(mapScale) {
      let mapScaleP = document.getElementById("ol-info-map-scale");
      if(!mapScaleP) return;
      mapScaleP.innerHTML = "<b>Maßstab: </b>" + scales[mapScale];
    }
  }

  const oemLabel = new OEMLabel();
  oemLabelRef.current = oemLabel;

  const northArrow = new NorthArrow();
  northArrowRef.current = northArrow;

  const mapInfo = new MapInfo();
  mapInfoRef.current = mapInfo;

  const scaleLine = new ScaleLine({
    bar: false,
    steps: 5,
    text: true,
    minWidth: "200",
    maxWidth: "300"
  });
  scaleLineRef.current = scaleLine;

  useEffect(() => {
    if(northArrowRef.current) {
      northArrowRef.current.setVisible(props.showNorthArrow);
    }
  }, [props.showNorthArrow, mapRef.current]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setLegendVisible(props.showLegend);
    }
  }, [props.showLegend]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileNameVisible(props.showFileName);
    }
  }, [props.showFileName]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileName(props.fileName);
    }
  }, [props.fileName]);

  useEffect(() => {
    if(mapInfoRef.current) {
      mapInfoRef.current.setMapScale(props.mapScale);
    }
  }, [props.mapScale]);

  //#region  Main Setup 

  //Autorefreshs
  function initMap() {
    mapElement.current.innerHTML = "";
    baseSrc.set('layerKey', "google_luftbild");
    var baseLayer = new TileLayer({
      type: "base_layer",
      source: baseSrc,
    });
    baseLayerRef.current = baseLayer;

    let initMap = new Map({
      controls: defaultControls({
        attribution: false,
        zoom: false,
      }).extend([oemLabel, northArrow, mapInfo, scaleLine]),
      target: mapElement.current,
      layers: [baseLayer],
      view: new View({
        center: fromLonLat(props.initMapPosition.pos ? props.initMapPosition.pos : [10.470215, 50.750766]),
        zoom: props.initMapPosition.zoom ? props.initMapPosition.zoom : 10,
        minZoom: 5,
        maxZoom: 22
      }),
    });

    mapRef.current = initMap;

    initMap.on('movestart', function(e) {
      props.onIsRenderingChanged(true);
    });

    initMap.on('rendercomplete', function(e) {
      props.onIsRenderingChanged(false);
    });

    initMap.on('moveend', function(e) {
      var mapView = mapRef.current.getView(), moveInitiatedProgrammatically = mapView.get('moveInitiatedProgrammatically') || false;
      mapView.unset('moveInitiatedProgrammatically');
      if(!moveInitiatedProgrammatically) {
        const map = e.map;
        const pos = toLonLat(map.getView().getCenter());
        let posObj = {
          pos: pos,
          zoom: map.getView().getZoom(),
          bounds: map.getView().calculateExtent(map.getSize())
        };
        setCurrentMapPos(posObj);
        props.onMapPositionChanged(posObj);
      }
    });

    initMap.getInteractions().forEach(function(interaction) {
      if (interaction instanceof MouseWheelZoom) {
        interaction.setActive(props.mapScale == "custom");
      }
    }, this);

    //Filename
    if(mapInfoRef.current) {
      mapInfoRef.current.setFileNameVisible(props.showFileName);
    }

    //Legend
    if(mapInfoRef.current) {
      mapInfoRef.current.setLegendVisible(props.showLegend);
    }

    setMap(initMap);
    props.onMapPositionChanged({
      pos: [
        props.poiPosition ? props.poiPosition.lon : 10.470215,
        props.poiPosition ? props.poiPosition.lat : 50.750766,
      ],
      zoom: initMap.getView().getZoom(),
      bounds: initMap.getView().calculateExtent(initMap.getSize())
    });
  }

  useEffect(() => {
    drawPoiGeometries();
    drawPoisPosition();
  }, [cfgs, selectedPois, selectedBlobs, selectedStyles, mapConfigs, mapRef.current, props.clusterIcons]);


  //Change Layer
  function handleMapLayer() {
    if (mapRef.current) {
      //baseLayer
      if(userSettings.sharedUiSettings?.enabledBaseLayer == null) {
        if(userSettings.sharedUiSettings == null) userSettings.sharedUiSettings = {}
        if(userSettings.sharedUiSettings.enabledBaseLayer == null) userSettings.sharedUiSettings.enabledBaseLayer = "defaultMap"
      }

      let selectedBaseLayer = userSettings.baseLayers ? userSettings.baseLayers.find((layer) => layer.layerKey == userSettings.sharedUiSettings?.enabledBaseLayer) : null;
      console.log("selectedBaseLayer", selectedBaseLayer)

      if (selectedBaseLayer) {
        let source = null;
        if (selectedBaseLayer.layerKey == "defaultMap") {
          source = new OSM();
        } else {
          if(selectedBaseLayer.wms) {
            source = new TileWMS({
              url: selectedBaseLayer.layerUrl,
              params: {"layers": selectedBaseLayer.specificLayer},
            });
          } else {
            source = new XYZ({
              url: selectedBaseLayer.layerUrl ? selectedBaseLayer.layerUrl.replaceAll("$", "") : "",
              crossOrigin: "anonymous",
            });
          }
        }
        source.set("layerKey", selectedBaseLayer.layerKey);
        source.set("wms", selectedBaseLayer.wms);
        let baseLayer = mapRef.current
          .getLayers()
          .getArray()
          .find((layer) => layer.get("type") == "base_layer");
        if (baseLayer) {
          console.log("baseLayer", baseLayer, source)
          let currentSource = baseLayer.getSource();
          if (currentSource && currentSource.get("layerKey") != source.get("layerKey")) {
            baseLayer.setSource(source);
          }
        }
      }

      //Overlay
      let newSelectedLayers = [];
      userSettings.sharedUiSettings?.enabledLayers?.map((enabledlayer, idx) => {
        let layer = userSettings.layers ? userSettings.layers.find((layer) => layer.layerKey == enabledlayer) : null;
        if (layer) {
          let source = null;
          if (layer.layerKey == "defaultMap") {
            source = new OSM();
          } else {
            if(layer.wms) {
              source = new TileWMS({
                url: layer.layerUrl,
                params: {"layers": layer.specificLayer},
                crossOrigin: "anonymous"
              });
            } else {
              source = new XYZ({
                url: layer.layerUrl.replaceAll("$", ""),
                crossOrigin: "anonymous",
              });
            }
          }
          source.set("layerKey", enabledlayer);
          source.set("wms", true);
          let newLayer = new TileLayer({
            type: "overlay_layer",
            source: source,
            zIndex: idx + 1,
          });
          newSelectedLayers.push(newLayer);
        }
      });

      let currentLayers = Object(mapRef.current.getLayers().getArray());

      //Remove old layers
      let layersToRemove = [];
      currentLayers.map((currentLayer) => {
        let foundLayer = newSelectedLayers ? newSelectedLayers.find((layer) => layer.getSource().get("layerKey") == currentLayer.getSource().get("layerKey")) : null;
        if (!foundLayer && currentLayer.get("type") == "overlay_layer") {
          layersToRemove.push(currentLayer);
        }
      });

      //Remove all Layers
      layersToRemove.map((layer) => {
        mapRef.current.removeLayer(layer);
      })

      //Add new layers
      newSelectedLayers.map((newLayer) => {
        let foundLayer = currentLayers ? currentLayers .find((layer) => layer.getSource().get("layerKey") == newLayer.getSource().get("layerKey")): null;
        if (!foundLayer) {
          mapRef.current.addLayer(newLayer);
        }
      });
    }
  }

  //Change Layer
  useEffect(() => {
    handleMapLayer();
  }, [userSettings.sharedUiSettings?.enabledBaseLayer, userSettings.sharedUiSettings?.enabledLayers, userSettings.sharedUiSettings?.enabledLayerFavorit]);

  useEffect(() => {
    initMap();
    handleMapLayer();
  }, []);

  return (
    <>
      <div
        id="basicmapexport"
        ref={mapElement}
        className="basicmapexport"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
      </div>
    </>
  );
}

export default BasicMapExport;

//#endregion