import { getArea, getLength } from "ol/sphere.js";
import { gpx as toGeoJSONGPX, kml as toGeoJSONKML } from "@tmcw/togeojson";

import Cookies from "universal-cookie";
import dayjs from "dayjs";
// import { refreshAccessToken } from "../providers/AuthProvider";
import { suffixes } from "./defines";

const cookies = new Cookies();


export function createConfigFromXML(xml) {
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xml, "text/xml");
  const rootElement = xmlDoc.documentElement;
  let config = {
    rows: [],
  };

  for (let child of rootElement.children) {
    var row = {};
    var attributes = child.textContent.split(",");

    for (let attrib of attributes) {
      var splitter = attrib.indexOf("=");
      var key = attrib.slice(0, splitter);
      var value = attrib.slice(splitter + 1, undefined);
      value = value.replaceAll("&comma;", ",");

      if (key == "list") {
        let list = [];
        let listItems = value.split(";");
        for (let listItem of listItems) {
          let item = {
            key: listItem.split(":", 2)[0],
            value: listItem.split(":", 2)[1],
          };
          list.push(item);
        }

        if (child.attributes["k"].value.includes("addInputControl")) {
          row[key] = list;
        } else {
          config[key] = list;
        }
      } else if (key.includes("ui.")) {
        let attribList = [];
        let attribs = value.split(";");

        for (let attrib of attribs) {
          var splitter = attrib.indexOf(":");
          var innerKey = attrib.slice(0, splitter);
          var innerValue = attrib.slice(splitter + 1, undefined);

          let item = {
            key: innerKey,
            value: innerValue,
          };
          attribList.push(item);
        }

        if (child.attributes["k"].value.includes("addInputControl")) {
          row[key] = attribList;
        } else {
          config[key] = attribList;
        }
      } else {
        if (child.attributes["k"].value.includes("addInputControl")) {
          row[key] = value;
        } else {
          config[key] = value;
        }
      }
    }

    if (child.attributes["k"].value.includes("addInputControl")) {
      config["rows"].push(row);
    }
  }
  return config;
}

export function getInterpretetTitle(poi, config) {
  let textTemplate = config.config["ui.android"].find((element) => element.key == "instanceMenuLabelTemplate");
  if (textTemplate != null && poi != null) {
    let text = fillTemplate(config.config, textTemplate.value, poi);
    return <div dangerouslySetInnerHTML={{ __html: text }} />;
  } else {
    return <div></div>;
  }
}

export function getSimpleLabel(label) {
  let parts = label.split("|");
  if (parts.length > 1) {
    return parts[parts.length - 1];
  } else {
    return label;
  }
}

export function formatText(text) {
  if (text.includes("<b>")) {
    return <b>{text.replaceAll("<b>", "").replaceAll("</b>", "").replaceAll("<b/>", "")}</b>;
  } else {
    return text;
  }
}

export function isSameCompany(username, loginName) {
  let poiCreationLoginName = loginName.split("-")[0];
  return username.includes(poiCreationLoginName);
}

export function getMainUserName(loginName) {
  let prefix = loginName.split("-")[0];
  let postfix = loginName.split("-")[1];
  return prefix + "-poicfg";
}

export async function callApiAsync(url, requestOptions, callback) {
  fetch(url, requestOptions)
    .then(async (resp) => {
      if (resp.status == 200) {
        return resp.json();
      }
      return Promise.reject(resp);
    })
    .then((json) => callback(json))
    .catch((error) => {
      callback(error);
    });
}

// export async function callApiTextAsync(url, requestOptions, callback) {
//   fetch(url, requestOptions)
//     .then((resp) => resp.text())
//     .then((json) => callback(json))
//     .catch((error) => {
//       //console.log(error);
//       callback(error);
//     });
// }

// export async function callApiRawAsync(url, requestOptions, callback) {
//   fetch(url, requestOptions)
//     .then((resp) => callback(resp))
//     .catch((error) => {
//       callback(error);
//     });
// }

export const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

export function decToHex(decimal) {
  // Convert the decimal to a hexadecimal string with base 16
  const hexString = decimal.toString(16);
  // Return the uppercase hexadecimal string
  return hexString.toUpperCase();
}

export function rgbToHexA(rgb) {
  // Check for invalid input length or values
  if (rgb.length !== 4 || rgb.some((val) => val < 0 || val > 255)) {
    throw new Error("Invalid RGB array. Values must be between 0 and 255.");
  }

  const [r, g, b, a] = rgb;
  const hex = [r, g, b].map((val) => val.toString(16).padStart(2, "0")).join("");
  return `#${hex}${a !== 1 ? (a * 255).toString(16).padStart(2, "0") : ""}`;
}

/*export function handleFileDownload(file, username, password, e) {
  if(file.base64) {
    console.log("has base64")
  } else {
    let auth = btoa(username + ":" + password);
    let formData = new FormData();
    formData.append('url', "https://portal.wood-in-vision.com/api/v1/blob/" + file.guid);
    formData.append("systemAuthToken", auth)
    let url = BACKEND_URL + "/api/Fetcher/GetByBridge";
    
    const requestOptions = {
      method: "POST"
    };

    fetch(url, {
      body: formData,
      ...requestOptions,
    })
    .then((response) => response.blob())
    .then(blob => {
      const href = window.URL.createObjectURL(blob);
      let fileName = file.l;
      if(!checkForSuffix(fileName)) {
        let suffix = suffixes[file.mimeType];
        fileName = fileName + "." + suffix;
      }
      downloadFile(href, fileName);
      e.stopPropagation();
    })
    .catch((error) => console.error(error));
  }
}*/

async function downloadFile(href, name) {
  const a = document.createElement("a");
  a.download = name;
  a.href = href;
  a.click();
  a.href = "";
}

export function openUrl(url, e) {
  if (!/^https?:\/\//i.test(url)) {
    url = "https://" + url;
  }
  window.open(url, "_blank");
  e.stopPropagation();
}

function checkForSuffix(str) {
  var suffix = str.slice((Math.max(0, str.lastIndexOf(".")) || Infinity) + 1);
  console.log(suffix);
  if (
    Object.entries(suffixes)
      .map((entry) => entry[1])
      .includes(suffix)
  ) {
    return suffix;
  }
  return null;
}

export function getLengthString(geom) {
  let length = getLength(geom);
  let output;
  if (length > 1000) {
    output = (Math.round((length / 1000) * 100) / 100).toFixed(2) + " km";
  } else {
    output = (Math.round(length * 100) / 100).toFixed(2) + " m";
  }
  return output;
}

export function getAreaSizeString(geom) {
  const area = getArea(geom);
  let output;
  if (area > 10000000) {
    output = (Math.round((area / 1000000) * 100) / 100).toFixed(2) + " km\xB2";
  } else if (area > 10000) {
    output = (Math.round((area / 10000) * 100) / 100).toFixed(2) + " ha";
  } else {
    output = (Math.round(area * 100) / 100).toFixed(2) + " m\xB2";
  }
  return output;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

//NEW WEB APPLICATION
export function getShouldInvertFromHex(hexColor) {
  if (hexColor == null) return;

  let hex = hexColor.replace("#", "");

  if (hex.length === 3) {
    hex = hex
      .split("")
      .map((x) => x + x)
      .join("");
  }

  if (hex.length !== 6) {
    throw new Error("Invalid HEX color.");
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  const luminance = (r, g, b) => {
    const [rr, gg, bb] = [r, g, b].map((c) => {
      c /= 255;
      return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4);
    });
    return 0.2126 * rr + 0.7152 * gg + 0.0722 * bb;
  };

  const bgLuminance = luminance(r, g, b);

  const whiteContrast = Math.abs(1 - bgLuminance); // Luminance of white is 1
  const blackContrast = Math.abs(0 - bgLuminance); // Luminance of black is 0

  return blackContrast > whiteContrast ? true : false;
}

export function getCorrectFileName(fileName) {
  var lastIndex = fileName?.lastIndexOf("_");
  return lastIndex > 0 ? fileName.substr(0, lastIndex) : fileName;
}

export function handleFileDownload(file, BASE_URL, bearerToken, e) {
  if (file.base64) {
    console.log("has base64");
  } else {
    let url = BASE_URL + "/Uploads/Files/" + file.FileUrl;

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(url, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        console.log(blob);
        if (blob.size == 0) alert("File download error");
        const href = window.URL.createObjectURL(blob);
        let fileName = file.FileName;
        downloadFile(href, fileName);
        e.stopPropagation();
      })
      .catch((error) => console.error(error));
  }
}

export function isAprilFirst() {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  return day === 1 && month === 3;
}

export function isChristmas() {
  const today = new Date();
  const year = today.getFullYear();
  //Check before new year
  const start = new Date(year, 11, 1);
  const end = new Date(year + 1, 0, 7);
  //Check after new year
  const start2 = new Date(year - 1, 11, 1);
  const end2 = new Date(year, 0, 7);
  return (today >= start && today <= end) || (today >= start2 && today <= end2);
}

export function isChristmasEve() {
  const today = new Date();
  const year = today.getFullYear();

  const start = new Date(year, 11, 24, 18, 0, 0);
  const end = new Date(year, 11, 24, 23, 59, 0);

  return today >= start && today <= end;
}

// Utility to parse XML strings
function parseXML(xmlString) {
  const parser = new DOMParser();
  return parser.parseFromString(xmlString, "application/xml");
}

export function convertToGeoJSON(input) {
  let geojson;

  console.log(input);

  try {
    const parsedInput = JSON.parse(input);
    if (parsedInput.type && parsedInput.features) {
      geojson = input;
    } else {
      throw new Error("Not a GeoJSON");
    }
  } catch (e) {
    const xmlData = parseXML(input);
    if (xmlData.getElementsByTagName("kml").length) {
      // KML format detected
      geojson = JSON.stringify(toGeoJSONKML(xmlData));
    } else if (xmlData.getElementsByTagName("gpx").length) {
      // GPX format detected
      geojson = JSON.stringify(toGeoJSONGPX(xmlData));
    } else {
      return "";
    }
  }

  // Step 3: Validate and return as GeoJSON
  return geojson;
}

export function svgToPng(svgBlob, backgroundColor, contrastThreshold = 128, outputSize = 512) {
  return new Promise((resolve, reject) => {
    function luminance(r, g, b) {
      const a = [r, g, b].map((v) => v / 255).map((v) => (v <= 0.03928 ? v / 12.92 : ((v + 0.055) / 1.055) ** 2.4));
      return 0.2126 * a[0] + 0.7152 * a[1] + 0.0722 * a[2];
    }

    function hexToRgb(hex) {
      const bigint = parseInt(hex.replace("#", ""), 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;
      return [r, g, b];
    }

    const [r, g, b] = hexToRgb(backgroundColor); //backgroundColor.match(/\d+/g).map(Number);

    const contrastColor = luminance(r, g, b) > 0.5 ? "black" : "white";

    const canvas = document.createElement("canvas");
    canvas.width = outputSize;
    canvas.height = outputSize;
    const ctx = canvas.getContext("2d");

    const img = new Image();
    img.onload = () => {
      ctx.drawImage(img, 0, 0, outputSize, outputSize);
      URL.revokeObjectURL(img.src);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, "image/png");
    };

    img.onerror = reject;

    const reader = new FileReader();
    reader.onload = () => {
      const svgData = reader.result.replaceAll(`fill="#FFFFFF"`, `fill="${contrastColor}"`);
      const newSvgBlob = new Blob([svgData], { type: "image/svg+xml;charset=utf-8" });
      img.src = URL.createObjectURL(newSvgBlob);
      img.style = "filter: invert(1);";
    };

    reader.onerror = reject;
    reader.readAsText(svgBlob);
  });
}

export function fillTemplate(config, template, values) {
  if (template == "") return "NoTitleSet - " + values._id.$oid;

  //console.log("TEMPLATE", template)

  const filledTemplate = template.replace(/\$\{([^\s}]+)\}/g, (match, key) => {
    key = key.replaceAll("=", "");
    let col = config.columns.find((col) => col.key == key);

    if (col != null && (col.fieldType == "SingleList" || col.fieldType == "MultiList")) {
      let configurationsJson;
      if (col.configurationsJson != null && col.configurationsJson != "") {
        try {
          configurationsJson = JSON.parse(col.configurationsJson);
        } catch (e) {
          console.log(e);
        }
      }

      let listItem = configurationsJson.listItems.find((listItem) => listItem.key == values[key]);
      if (listItem != null) {
        return listItem?.value || "";
      } else {
        return "";
      }
    } else if (col != null && col.fieldType == "DynamicList") {
      console.log("DYNAMIC EVALUATION");
      /*let configurationsJson;
      if(col.configurationsJson != null && col.configurationsJson != "") {
        try {
          configurationsJson = JSON.parse(col.configurationsJson);
        } catch(e) {
          console.log(e)
        }
      }

      let listItem = configurationsJson.listItems.find((listItem) => listItem.key == values[key]);
      if(listItem != null) {
        return listItem?.value || "";
      } else {
        return "";
      }*/
      return "DYNAMIC LIST";
    } else {
      return values != null && values[key] != null ? values[key] : "";
    }
  });
  return filledTemplate;
}

export function geoJSONToKML(geojsonString) {
  const kmlHeader = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2">
    <Document>`;
  const kmlFooter = `</Document></kml>`;

  let kmlBody = "";

  let geojson = JSON.parse(geojsonString);
  geojson.features.forEach((feature) => {
    const { type } = feature.geometry;
    const coords = feature.geometry.coordinates;
    const properties = feature.properties || {};
    const name = properties.name || "Feature";

    if (type === "Point") {
      kmlBody += `<Placemark><name>${name}</name><Point><coordinates>${coords[0]},${coords[1]}</coordinates></Point></Placemark>`;
    } else if (type === "LineString") {
      const coordString = coords.map((c) => `${c[0]},${c[1]}`).join(" ");
      kmlBody += `<Placemark><name>${name}</name><LineString><coordinates>${coordString}</coordinates></LineString></Placemark>`;
    } else if (type === "Polygon") {
      const coordString = coords[0].map((c) => `${c[0]},${c[1]}`).join(" ");
      kmlBody += `<Placemark><name>${name}</name><Polygon><outerBoundaryIs><LinearRing><coordinates>${coordString}</coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark>`;
    }
  });

  return kmlHeader + kmlBody + kmlFooter;
}

export function parseSaveJson(jsonString) {
  let json = {};
  try {
    json = JSON.parse(jsonString);
  } catch (e) {
    console.log(e);
  }
  return json;
}

export function ObjectID() {
  let timestamp = Math.floor(Date.now() / 1000); // 4-byte timestamp (seconds since epoch)

  const randomBytes = new Uint8Array(7); // 7 random bytes
  crypto.getRandomValues(randomBytes);

  let objectId = new Uint8Array(12); // Total 12 bytes
  let timestampBytes = new Uint8Array(4);

  for (let i = 3; i >= 0; i--) {
    timestampBytes[i] = timestamp & 0xff;
    timestamp = timestamp >> 8;
  }

  objectId.set(timestampBytes, 0); // 4-byte timestamp
  objectId.set(randomBytes, 4); // 7-byte randomness

  return Array.from(objectId)
    .map((byte) => byte.toString(16).padStart(2, "0"))
    .join("");
}

export function generatePassword() {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*";
  const passwordLength = 10;
  let password = "";

  for (let i = 0; i < passwordLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }

  return password;
}

export function alphabeticSort(a, b) {
  if (a.tableName < b.tableName) {
    return -1;
  }
  if (a.tableName > b.tableName) {
    return 1;
  }
  return 0;
}

export const generateOid = () => {
  let oid = "";
  for (let i = 0; i < 24; i++) {
    oid += Math.floor(Math.random() * 16).toString(16); // Random hex digit (0-15)
  }
  return oid;
};

export function lowerFirstCharKeys(obj) {
  // Helper function to lower the first character of a key
  function lowerFirstChar(str) {
    return str.charAt(0).toLowerCase() + str.slice(1);
  }

  // Check if the input is an object or an array
  if (Array.isArray(obj)) {
    return obj.map((item) => lowerFirstCharKeys(item)); // Process each item in the array
  } else if (typeof obj === "object" && obj !== null) {
    const newObject = {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const newKey = lowerFirstChar(key); // Lowercase the first character of the key
        newObject[newKey] = lowerFirstCharKeys(obj[key]); // Recurse for nested objects/arrays
      }
    }
    return newObject;
  }
  return obj; // Return non-object values as-is
}


//GetFeatureInfo to Map
export function extractTableKeyValuePairs(xmlString) {
  const keyValueMap = {};
  try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, "text/html");
      const tables = xmlDoc.getElementsByTagName("table");

      for (let i = 0; i < tables.length; i++) {
          const table = tables[i];
          const rows = table.getElementsByTagName("tr");

          // Check for vertical table format: exactly two rows with the same number of cells.
          if (rows.length === 2) {
              const firstRowCells = getCells(rows[0]);
              const secondRowCells = getCells(rows[1]);
              if (firstRowCells.length === secondRowCells.length && firstRowCells.length > 1) {
                  for (let j = 0; j < firstRowCells.length; j++) {
                      const key = firstRowCells[j].textContent.trim();
                      const value = secondRowCells[j].textContent.trim();
                      if (key) keyValueMap[key] = value;
                  }
                  continue; // Move to the next table after processing as vertical.
              }
          }

          // Process as a horizontal table if not vertical.
          for (let j = 0; j < rows.length; j++) {
              const cells = getCells(rows[j]);
              if (cells.length >= 2) {
                  const key = cells[0].textContent.trim();
                  const value = cells[1].textContent.trim();
                  if (key) keyValueMap[key] = value;
              }
          }
      }
  } catch (e) {
      console.error("Error parsing XML:", e);
  }
  return keyValueMap;
}

function getCells(row) {
  const cells = [];
  const childNodes = row.childNodes;
  for (let i = 0; i < childNodes.length; i++) {
      const node = childNodes[i];
      if (node.nodeType === 1) { // Element node
          const tagName = node.tagName.toLowerCase();
          if (tagName === "td" || tagName === "th") {
              cells.push(node);
          }
      }
  }
  return cells;
}
