import { ADMIN_URL, GATEWAY_URL } from "../../js/defines";
import { Add, DeleteForeverOutlined, InfoOutlined, Save } from "@mui/icons-material";
import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField, Typography } from "@mui/material";
import { GridToolbarContainer, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { DataGridPremium } from "@mui/x-data-grid-premium";
import TimedButton from "../../widgets/components/TimedButton";
import { callApiRawAsync } from "../../js/helper";
import { useApi } from "../../hooks/useApi";
import { useServerData } from "../../providers/DataProvider";
import { useTheme } from "@emotion/react";
import { v4 as uuidv4 } from 'uuid';

function StyleConfiguration({bearerToken}) {
  const theme = useTheme();
  const api = useApi();

  const [styleHeaders, setStyleHeaders] = useState([]);
  const [selectedStyle, setSelectedStyle] = useState(null);

  const [selectedRules, setSelectedRules] = useState([]);

  const [styleToDelete, setStyleToDelete] = useState(null);

  const [processing, isProcessing] = useState(false);

  const { cfgs, setStatusMsg } = useServerData();
  const apiRef = useGridApiRef();


  function fetchStyleHeaders() {
    let url = GATEWAY_URL + ADMIN_URL + "/api/StyleHeaders";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };
    api.customRequest(url, requestOptions, async(resp) => {
      let items = await resp?.json()
      console.log("🚀 ~ api.customReque /api/StyleHeaders", items)
      if(items){
      setStyleHeaders(items);
      }
    });
  }

  useEffect(() => {
    let newStyle = styleHeaders?.find((i) => i.id == selectedStyle?.id) ?? null;
    setSelectedStyle(newStyle);
  }, [styleHeaders]);


  function stylePOST() {
    let tmpStyle = JSON.parse(JSON.stringify(selectedStyle));

    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    allRows = allRows?.filter((i) => i.isNew)?.map((rule) => {
      delete rule.isNew;
      delete rule.id;
      return rule;
    });

    tmpStyle.styleRules = allRows;

    delete tmpStyle?.isNew;

    let url = GATEWAY_URL + ADMIN_URL + "/api/StyleHeaders";
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(tmpStyle)
    };
    isProcessing(true);
    api.customRequest(url, requestOptions, (response) => {
      if(response.ok) {
        fetchStyleHeaders();
        setStatusMsg({
          type: "Success",
          message: "Style saved successfully!"
        })
      }
      isProcessing(false);
    });
  }

  function stylePUT() {
    let tmpStyle = JSON.parse(JSON.stringify(selectedStyle));

    const rowModels = apiRef.current.getRowModels();
    let allRows = Array.from(rowModels.values());
    allRows = allRows?.map((rule) => {
      if(rule.isNew) {
        delete rule.id;
      }
      delete rule.isNew;
      return rule;
    });
    tmpStyle.styleRules = allRows;

    let url = GATEWAY_URL + ADMIN_URL + "/api/StyleHeaders/" + tmpStyle?.id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(tmpStyle)
    };
    isProcessing(true);
    api.customRequest(url, requestOptions, (response) => {
      if(response.ok) {
        fetchStyleHeaders();
        setStatusMsg({
          type: "Success",
          message: "Style saved successfully!"
        })
      }
      isProcessing(false);
    });
  }

  function styleDELETE(styleToDelete) {
    /*let url = GATEWAY_URL + ADMIN_URL + "/api/StyleHeaders/" + styleToDelete?.id;
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearerToken);
    myHeaders.append("Content-Type", "application/json");
    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };
    api.customRequest(url, requestOptions, (response) => {
      // await and convert to json IMPORTANT !!!!!!
      fetchStyleHeaders();
    });*/
  }


  function createNewStyle() {
    const id = uuidv4();

    let newStyle = {
      styleName: "New Style",
      styleRules: [
        {
          id: id,
          target: "marker",
          order: 0,
          styleType: "fill_color",
          sevices: "",
          expression: "true",
          value: "red",
          legendText: "Red Dot Legend",
          comment: "Red Dot Comment",
          isNew: true
        }
      ],
      comment: "New Comment",
      tableIds: [],
      isNew: true
    }
    setSelectedStyle(newStyle);
  }

  function changeStyleProperty(key, value) {
    let newStyle = {...selectedStyle};
    newStyle[key] = value;
    setSelectedStyle(newStyle);
  }

  const styleRuleCols = [
    { field: 'id', headerName: 'ID', width: 50, editable: false },
    {
      field: 'target',
      headerName: 'Target',
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: [
        { id: "marker", label: "Marker" },
        { id: "map", label: "Map" },
      ],
      width: 150,
      editable: true,
      flex: 0.75
    },
    {
      field: 'order',
      headerName: 'Order',
      width: 110,
      editable: true,
      flex: 0.5
    },
    {
      field: 'styleType',
      headerName: 'Style Type',
      type: "singleSelect",
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: [
        { id: "fill_color", label: "Fill Color" },
        { id: "stroke_color", label: "Stroke Color" },
        { id: "stroke_width", label: "Stroke Width" },
        { id: "circle_d", label: "Circle Diameter" },
        { id: "font_color", label: "Font Color" },
        { id: "font_bg_color", label: "Font Shadow" },
        { id: "font_size", label: "Font Size" },
        { id: "icon_url", label: "Icon URL" },
        { id: "label", label: "Label" }

      ],
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'sevices',
      headerName: 'Devices',
      type: 'singleSelect',
      getOptionLabel: (value) => value.label,
      getOptionValue: (value) => value.id,
      valueOptions: [
        { id: "webApp", label: "Web App" },
        { id: "mobileApp", label: "Mobile App" },
      ],
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'expression',
      headerName: 'Expression',
      width: 110,
      editable: true,
      flex: 2
    },
    {
      field: 'value',
      headerName: 'Value',
      width: 110,
      editable: true,
      flex: 1
    },
    {
      field: 'legendText',
      headerName: 'Legend Text',
      width: 110,
      editable: true,
      flex: 3
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 110,
      editable: true,
      flex: 2
    }
  ];

  function onSelectionModel(row) {
    setSelectedRules(row);
  }

  useEffect(() => {
    fetchStyleHeaders();
  }, []);

  console.log(styleToDelete)

  return (
    <div style={{ display: "flex", backgroundColor: theme.palette.background.default, flexDirection: "column", width: "100%", height: "calc(100vh)", overflow: "hidden" }}>
      <div style={{margin: "30px", display: "flex", flexDirection: "column", gap: "20px"}}>
        <Typography fontSize={30} fontWeight={600} color={theme.palette.text.primary}>Style Configurator</Typography>

        <div style={{display: "flex", gap: "20px"}}> 
          <Autocomplete
            id="free-solo-demo"
            fullWidth
            size="small"
            options={styleHeaders?.map((style) => ({id: style.id, label: style?.styleName ?? "Unknown"}))}
            renderInput={(params) => <TextField {...params} label="Styles" />}
            onChange={(option, value) => {
              let selStyle = styleHeaders.find((style) => style.id === (value != null ? value.id : null));
              setSelectedStyle(selStyle);
            }}
          />
          <IconButton onClick={() => createNewStyle()}><Add/></IconButton>
          <IconButton disabled={!selectedStyle} onClick={() => setStyleToDelete(selectedStyle)}><DeleteForeverOutlined/></IconButton>
          <IconButton disabled={!selectedStyle} onClick={() => {
            if(selectedStyle.isNew != undefined) {
              stylePOST();
            } else {
              stylePUT();
            }
          }}>{!processing ? <Save/> : <CircularProgress size={"24px"}/>}</IconButton>
        </div>

        <div style={{display: "flex", flexDirection: "column", gap: "20px"}}> 
        
          <Typography fontSize={18} fontWeight={600} color={theme.palette.text.primary}>Main Information</Typography>
          <div style={{display: "flex", gap: "20px"}}>
            <TextField disabled={!selectedStyle} label="Name" size="small" value={selectedStyle?.styleName ?? ""} onChange={(e) => changeStyleProperty("styleName", e.target.value)}></TextField>
            <TextField disabled={!selectedStyle} style={{width: "350px"}} label="Comment" size="small" value={selectedStyle?.comment ?? ""} onChange={(e) => changeStyleProperty("comment", e.target.value)}></TextField>
            <Autocomplete
              fullWidth
              multiple
              size="small"
              disabled={!selectedStyle}
              value={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"}))?.filter((i) => selectedStyle?.tableIds?.includes(i?.id) )}
              options={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"}))}
              renderInput={(params) => <TextField {...params} label="Tables" />}
              isOptionEqualToValue={(option, value) => option.id == value.id}
              onChange={(option, value) => {
                changeStyleProperty("tableIds", value.map((i) => i.id));
              }}
            />
          </div>

          <Typography fontSize={18} fontWeight={600} color={theme.palette.text.primary}>Rules</Typography>
          <Box sx={{ height: "calc(100vh - 340px)", width: '100%' }}>
            <DataGridPremium
              apiRef={apiRef}
              rows={selectedStyle?.styleRules ?? []}
              columns={styleRuleCols}
              checkboxSelection
              onRowSelectionModelChange={onSelectionModel}
              editMode="row"
              density="compact"
              slots={{
                toolbar: EditToolbar
              }}
              slotProps={{
                toolbar: {
                  selectedStyle,
                  setSelectedStyle,
                  selectedRules,
                  fetchStyleHeaders,
                  bearerToken,
                  apiRef
                }
              }}
            />
          </Box>

          <Dialog open={styleToDelete != null}>
            <DialogTitle>
              <div style={{display: "flex", gap: 8}}>
                <InfoOutlined style={{alignSelf: "center"}} color='error'/>
                <Typography fontSize={20}>Delete Style</Typography>
              </div>
            </DialogTitle>
            <DialogContent>
              Do you want to delete "{styleToDelete?.styleName}"?
            </DialogContent>
            <DialogActions>
              <Button style={{ color: "grey", width: "115px" }} onClick={() => setStyleToDelete(null)}>
                Cancel
              </Button>
              <TimedButton
                style={{ width: "115px" }}
                color={"error"}
                variant="contained"
                onClick={() => { styleDELETE(styleToDelete) }}
                autoFocus
                timeout={10}
              >
                Delete
              </TimedButton>
            </DialogActions>
          </Dialog>

        </div>
      </div>
    </div>
  );
}

export default StyleConfiguration;


function EditToolbar(props) {
  const api = useApi();

  const { selectedStyle, selectedRules, fetchStyleHeaders, apiRef } = props;
  const [processing, isProcessing] = useState(false);

  const handleClick = () => {
    const id = uuidv4();
    let newItem = {
      id: id,
      target: "marker",
      order: 0,
      styleType: "fill_color",
      sevices: "",
      expression: "true",
      value: "red",
      legendText: "",
      comment: "",
      isNew: true,
    }
    apiRef.current.updateRows([newItem]);
  };

  const handleDelete = () => {
    {selectedRules.map((ruleId) => {
      let url = GATEWAY_URL + ADMIN_URL + "/api/StyleRules/" + ruleId;
      const myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + props.bearerToken);
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow"
      };
      isProcessing(true);
      api.customRequest(url, requestOptions, () => {
        fetchStyleHeaders();
        isProcessing(false);
      });
    })}
  };

  return (
    <GridToolbarContainer>
      <IconButton disabled={!selectedStyle} size='small' onClick={handleDelete}>{!processing ? <DeleteForeverOutlined/> : <CircularProgress size={"24px"}/>}</IconButton>
      <IconButton disabled={!selectedStyle} onClick={handleClick}><Add/></IconButton>
    </GridToolbarContainer>
  );
}