import { ADMIN_URL, GATEWAY_URL, dataTypes, tableIcons } from "../../js/defines";
import { Add, Code, CodeOff, Delete, DeleteForever, DragIndicator, ExpandLess, ExpandMore, Save, Tune, WarningAmberOutlined } from "@mui/icons-material";
import { Autocomplete, Box, Button, ButtonBase, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListSubheader, Menu, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { getShouldInvertFromHex, svgToPng, toBase64 } from "../../js/helper";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Editor from '@monaco-editor/react';
import { MuiColorInput } from "mui-color-input";
import TimedButton from "../../widgets/components/TimedButton";
import { useApi } from "../../hooks/useApi";
import { useServerData } from "../../providers/DataProvider";
import { useTheme } from "@emotion/react";
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from "../../providers/AuthProvider";
import useSaveShortcut from "../../hooks/useSaveShortcut";
import { useLocation } from "react-router-dom";

function NavBarItem(props) {
  const {key, name, selected, onClick} = props
  return(
    <MenuItem style={{fontWeight: selected ? "bold" : null}} onClick={onClick}>{name}</MenuItem>
  )
}

function EditorTab(props) {
  const {item, selected, onRename, onSave, onClick} = props
  const [rename, setRename] = useState(null);
  return(rename != null ? 
  <TextField
    value={rename}
    size="small"
    onChange={(e) => setRename(e.target.value)}
    onKeyDown={(e) => {
      if (e.key === "Enter") {
        onRename(item.key, rename)
        setRename(null)
      }
    }}
  />
  :
  <Box>
    <MenuItem
      style={{ fontWeight: selected ? "bold" : null, fontStyle: item.edited ? "italic" : null, height: 36 }}
      onClick={() => onClick(item.key)}
      onDoubleClick={() => {
        onClick(item.key);
        setRename(item.name);
      }}
    >
      {item.name} {item.edited ? "(edited)" : null}
      {item.edited ? (
        <IconButton
          size="small"
          sx={{ width: 24, height: 24, padding: 0 }}
          onClick={(event) => {
            event.stopPropagation();
            onSave();
          }}
        >
          <Save />
        </IconButton>
      ) : null}
    </MenuItem>
  </Box>)
}

function DetailsDialog({debugMode, selectedCfg, tableDetailsOpen, changeTableProperty, setTableDetailsOpen,  configItems, columnToEdit, changeProperty, changeMobileConfig, changeWebConfig, setColumnToEdit}) {
  const { globalScripts } = useServerData();

  const [detailsTab, setDetailsTab] = useState(1);
  const { userState } = useAuth();
  const api = useApi();

  //Table
  let configurationJson;
  if(selectedCfg?.configurationJson != null && selectedCfg?.configurationJson != "") {
    try {
      configurationJson = JSON.parse(selectedCfg.configurationJson);
    } catch(e) {
      console.log(e)
    }
  }
  if(!configurationJson) {
    configurationJson = {
      script: null
    }
  }

  //Column
  let configurationsJson;
  if(columnToEdit?.configurationsJson != null && columnToEdit?.configurationsJson != "") {
    try {
      configurationsJson = JSON.parse(columnToEdit.configurationsJson);
    } catch(e) {
      console.log(e)
    }
  }

  let defaultList = [
    {key: "key0", value: "value0"}
  ]

  if(!configurationsJson) {
    configurationsJson = {
      listItems: defaultList,
      script: null
    }
  }



  const [selectedKey, setSelectedKey] = useState(null);
  const [currentScript, setCurrentScript] = useState("");
  const scriptsRef = useRef([...globalScripts]);
  const scriptCounterRef = useRef(globalScripts.length + 1);
  const [forceUpdate, setForceUpdate] = useState(0);


  const handleSelectScript = useCallback((key) => {
    if (selectedKey) {
      const scriptIndex = scriptsRef.current.findIndex((s) => s.key === selectedKey);
      if (scriptIndex !== -1) {
        scriptsRef.current[scriptIndex].value = currentScript;
      }
    }

    const newScript = scriptsRef.current.find((s) => s.key === key);
    setCurrentScript(newScript ? newScript.value : "");
    setSelectedKey(key);
  }, [selectedKey, currentScript]);

  const handleScriptChange = useCallback((newValue) => {
    setCurrentScript(newValue);
    updateEditedState(selectedKey, true);
  }, []);

  const handleAddScript = useCallback(() => {
    const newScript = {
      key: String(scriptCounterRef.current),
      name: `NewScript${scriptCounterRef.current}.js`,
      value: "// Start coding...",
    };
    scriptCounterRef.current += 1;

    scriptsRef.current.push(newScript);
    handleSelectScript(newScript.key);
  }, [handleSelectScript]);

  const updateEditedState = useCallback((key, edited) => {
    const scriptIndex = scriptsRef.current.findIndex((s) => s.key === key);
    if (scriptIndex !== -1) {
      scriptsRef.current[scriptIndex].edited = edited;
      setForceUpdate((prev) => prev + 1);
    }
  }, []);

  const renameScript = useCallback((key, newName) => {
    const scriptIndex = scriptsRef.current.findIndex((s) => s.key === key);
    if (scriptIndex !== -1) {
      scriptsRef.current[scriptIndex].name = newName;
      setForceUpdate((prev) => prev + 1);
    }
  }, []);


  function sendGlobalScript() {
    let script = scriptsRef.current[scriptsRef.current.findIndex((s) => s.key === selectedKey)];
    script.value = currentScript

    let url = GATEWAY_URL + ADMIN_URL + "/api/GlobalScript";

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + userState.token);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(script)
    };

    console.log(requestOptions)

    api.customRequest(url, requestOptions, async (response) => {
      if(response.ok) {
        updateEditedState(selectedKey, false);
      }
    })
  }

  useSaveShortcut(sendGlobalScript);

  useEffect(() => {
    if(columnToEdit != null) {
      setDetailsTab(2)
    } else {
      setDetailsTab(1);
    }
  }, [columnToEdit, tableDetailsOpen]);

  return(
    <Dialog open={tableDetailsOpen} sx={"xl"} fullScreen>
      <DialogTitle id="alert-dialog-title">
        {columnToEdit?.columnName} - Zeilendetails
      </DialogTitle>
      <DialogContent style={{ display: "flex", height: "100%" }}>
        <Box style={{ width: "200px" }}>
          <NavBarItem key={"global"} name={"Global"} selected={detailsTab == 0} onClick={() => setDetailsTab(0)} />
          <NavBarItem key={"table"} name={"Modul"} selected={detailsTab == 1} onClick={() => setDetailsTab(1)} />
            {columnToEdit != null ?
            <>
              <NavBarItem key={"main"} name={"Allgemein"} selected={detailsTab == 2} onClick={() => setDetailsTab(2)} />
              <NavBarItem key={"web"} name={"Web"} selected={detailsTab == 3} onClick={() => setDetailsTab(3)} />
              <NavBarItem key={"mobile"} name={"Mobil"} selected={detailsTab == 4} onClick={() => setDetailsTab(4)} />
            </> : null}
        </Box>
        <Box style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          {detailsTab == 0 ? (
            <Box style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <Typography style={{padding: 16, fontSize: 16, fontWeight: "bold"}}>Globales Script</Typography>
              <Box style={{ flexGrow: 1, display: "flex" }}>
                <Box style={{ display: "flex", flexDirection: "column", width: "200px" }}>
                  {scriptsRef.current.map((i) => {
                    return(
                      <EditorTab
                        item={i}
                        selected={i.key == selectedKey}
                        onRename={(key, newName) => renameScript(key, newName)}
                        onClick={(selected) => handleSelectScript(selected)}
                        onSave={() => sendGlobalScript()}
                      />
                    )
                  })}
                  <IconButton style={{width: 40, height: 40, justifyContent: "center"}} onClick={() => handleAddScript()}><Add/></IconButton>
                </Box>
                <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "50%", gap: 8 }}>
                  <Editor
                    language="javascript"
                    options={{ tabSize: 2, automaticLayout: true }}
                    value={currentScript}
                    onChange={handleScriptChange}
                    style={{ height: "100%" }} // Ensures the editor takes up full height
                  />
                </Box>
              </Box>
            </Box>
          ) : detailsTab == 1 ? (
            <Box style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <Typography style={{padding: 16, fontSize: 16, fontWeight: "bold"}}>Modulscript</Typography>
              <Box style={{ flexGrow: 1, display: "flex" }}>
                <Box style={{ display: "flex", flexDirection: "column", width: "200px" }}>
                  <Box style={{display: "flex", padding: 16}}>
                    <Typography style={{ alignContent: "center" }}>Indepentent</Typography>
                    <Checkbox checked={selectedCfg?.isIndependent} onChange={(e) => changeTableProperty("isIndependent", e.target.checked)}/>
                  </Box>
                </Box>
                <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", gap: 8 }}>
                  <Typography style={{ alignContent: "center", fontWeight: "bold" }}>Table Script</Typography>
                  <Editor
                    language="javascript"
                    options={{ tabSize: 2, automaticLayout: true }}
                    value={debugMode ? configurationJson?.devscript : configurationJson?.script}
                    onChange={(value) => {
                      try {
                        if(debugMode) {
                          if (!configurationJson.devscript) {
                            configurationJson.devscript = "";
                          }
                          configurationJson.devscript = value;
                        } else {
                          if (!configurationJson.script) {
                            configurationJson.script = "";
                          }
                          configurationJson.script = value;
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    style={{ height: "100%" }} // Ensures the editor takes up full height
                  />
                </Box>
              </Box>
            </Box>
          ) : detailsTab == 2 ? (
            <Box style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
              <Typography style={{padding: 16, fontSize: 16, fontWeight: "bold"}}>Allgemein</Typography>
              <Box style={{ flexGrow: 1, display: "flex" }}>
                <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "50%" }}>
                  <Box style={{display: "flex", padding: 16}}>
                    <Typography style={{ alignContent: "center" }}>Required</Typography>
                    <Checkbox checked={columnToEdit?.required} onChange={(e) => changeProperty(columnToEdit, "required", e.target.checked)}/>
                  </Box>

                  {columnToEdit?.fieldType == "SingleList" || columnToEdit?.fieldType == "MultiList" ?
                    <Box style={{display: "flex", flexDirection: "column", paddingInline: 16}}>
                      <Typography style={{alignContent: "center"}}>List Items</Typography>
                      <Editor
                        height={"400px"}
                        language="json"
                        options={{ tabSize: 2, automaticLayout: true }}
                        value={JSON.stringify(configurationsJson?.listItems, null, 2)}
                        onChange={(value) => {
                          try {
                            if(!configurationsJson.listItems) {
                              configurationsJson.listItems = []
                            }
                            configurationsJson.listItems = JSON.parse(value);
                            changeProperty(columnToEdit, "configurationsJson", JSON.stringify(configurationsJson))
                          } catch (e) {
                            console.log(e)
                          }
                        }}
                      />
                    </Box> : null}

                  {columnToEdit?.fieldType == "DynamicList" ?
                    <Box style={{display: "flex", flexDirection: "column", paddingInline: 16}}>
                      <Typography style={{alignContent: "center"}}>List Items</Typography>
                      <Editor
                        height={"400px"}
                        language="json"
                        options={{ tabSize: 2, automaticLayout: true }}
                        value={JSON.stringify(configurationsJson, null, 2)}
                        onChange={(value) => {
                          try {
                            configurationsJson = JSON.parse(value);
                            changeProperty(columnToEdit, "configurationsJson", JSON.stringify(configurationsJson))
                          } catch (e) {
                            console.log(e)
                          }
                        }}
                      />
                    </Box>  : null}

                </Box>
                <Box style={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "50%", gap: 8 }}>
                  <Typography style={{ alignContent: "center", fontWeight: "bold" }}>Column Script</Typography>
                  <Editor
                    language="javascript"
                    options={{ tabSize: 2, automaticLayout: true }}
                    value={debugMode ? configurationsJson?.devscript : configurationsJson?.script}
                    onChange={(value) => {
                      try {
                        if(debugMode) {
                          if (!configurationsJson.devscript) {
                            configurationsJson.devscript = "";
                          }
                          configurationsJson.devscript = value;
                        } else {
                          if (!configurationsJson.script) {
                            configurationsJson.script = "";
                          }
                          configurationsJson.script = value;
                        }
                      } catch (e) {
                        console.log(e);
                      }
                    }}
                    style={{ height: "100%" }} // Ensures the editor takes up full height
                  />
                </Box>
              </Box>
            </Box>
          ) : detailsTab == 3 ? (
            <Box>
              <Typography style={{padding: 16, fontSize: 16, fontWeight: "bold"}}>Web</Typography>
              <Box style={{display: "flex", padding: 16}}>
                <Typography style={{ alignContent: "center" }}>Visible</Typography>
                <Checkbox checked={columnToEdit?.webUIConfig?.visible} onChange={(e) => changeWebConfig(columnToEdit, "visible", e.target.checked)}/>
              </Box>
              <Box style={{display: "flex", padding: 16}}>
                <Typography style={{ alignContent: "center" }}>Editable</Typography>
                <Checkbox checked={columnToEdit?.webUIConfig?.editable} onChange={(e) => changeWebConfig(columnToEdit, "editable", e.target.checked)}/>
              </Box>
            </Box>
          ) : (
            <Box>
              <Typography style={{padding: 16, fontSize: 16, fontWeight: "bold"}}>Mobile</Typography>
              <Box style={{display: "flex", padding: 16}}>
                <Typography style={{ alignContent: "center" }}>Visible</Typography>
                <Checkbox checked={columnToEdit?.mobileUIConfig?.visible} onChange={(e) => changeMobileConfig(columnToEdit, "visible", e.target.checked)}/>
              </Box>
              <Box style={{display: "flex", padding: 16}}>
                <Typography style={{ alignContent: "center" }}>Editable</Typography>
                <Checkbox checked={columnToEdit?.mobileUIConfig?.editable} onChange={(e) => changeMobileConfig(columnToEdit, "editable", e.target.checked)}/>
              </Box>
              <Box style={{display: "flex", padding: 16, gap: 16}}>
                <Typography style={{ alignContent: "center" }}>Sector</Typography>
                <Autocomplete
                  size="small"
                  fullWidth
                  options={configItems?.filter((i) => i.type == "sector")?.map((sector) => ({id: sector.sectorKey, label: sector.sectorName})) ?? []}
                  renderInput={(params) => <TextField {...params} label="Sector" />}
                  value={configItems?.filter((i) => i.type == "sector")?.map((sector) => ({id: sector.sectorKey, label: sector.sectorName})).find((i) => i.id == columnToEdit?.mobileUIConfig?.sectorKey) ?? []}
                  onChange={(option, value) => {
                    changeMobileConfig(columnToEdit, "sectorKey", value?.id ?? null);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="text" onClick={() => {
          setTableDetailsOpen(false)
          setColumnToEdit(null)
        }}>Abbrechen</Button>
        <Button variant="text" onClick={() => {
          //Module Script
          changeTableProperty("configurationJson", JSON.stringify(configurationJson));
          
          //Column Script
          if(columnToEdit != null) {
            changeProperty(columnToEdit, "configurationsJson", JSON.stringify(configurationsJson));
          }
          setTableDetailsOpen(false)
          setColumnToEdit(null)
        }}>Fertig</Button>
      </DialogActions>
    </Dialog>
  )
}

function RenderColumnItem({setTableDetailsOpen, item, availabeColumns, changeProperty, removeItem, setColumnToEdit, tables, selectedTable, configItems, sectorItem}) {
  let configurationsJson;
  if(item.configurationsJson != null && item.configurationsJson != "") {
    try {
      configurationsJson = JSON.parse(item.configurationsJson);
    } catch(e) {
      console.log(e)
    }
  }

  let groupedDataTypes = useMemo(() => {
    let groups = {}

    groups["References"] = {
      ParentOIds: dataTypes.ParentOIds,
      AssignedUsers: dataTypes.AssignedUsers,
      NestedTable: dataTypes.NestedTable
    }

    groups["Standard"] = {
      Label: dataTypes.Label,
      Int: dataTypes.Int,
      Float: dataTypes.Float,
      String: dataTypes.String,
      MultilineText: dataTypes.MultilineText,
      Email: dataTypes.Email,
      PhoneNumber: dataTypes.PhoneNumber,
      Href: dataTypes.Href,
      Bool: dataTypes.Bool,
      Button: dataTypes.Button,
      Archive: dataTypes.Archive
    }

    groups["Dates"] = {
      Date: dataTypes.Date,
      DateTime: dataTypes.DateTime
    }

    groups["Lists"] = {
      SingleList: dataTypes.SingleList,
      MultiList: dataTypes.MultiList,
      DynamicList: dataTypes.DynamicList
    }

    groups["Map"] = {
      Location: dataTypes.Location,
      Map: dataTypes.Map
    }

    groups["Documents"] = {
      File: dataTypes.File,
      Signature: dataTypes.Signature,
      Image: dataTypes.Image,
    }

    return(groups);
  });

  return(
    <div style={{display: "flex", gap: "5px", backgroundColor: sectorItem != true && item.mobileUIConfig.sectorKey != null ? "#ffb0b0" : null}}>
      <DragIndicator style={{alignSelf: "center", color: sectorItem != undefined ? "#888": null}}/>
      <Autocomplete
        freeSolo
        size="small"
        style={{flex: 1}}
        options={availabeColumns}
        value={item.key}
        disabled={!item.isNew || item.fieldType == "ParentOIds" || item.fieldType == "Location" || item.fieldType == "Map" || item.fieldType == "Archive" || item.fieldType == "NestedTable" || item.fieldType == "AssignedUsers"}
        renderInput={(params) => <TextField {...params} label="Key" />}
        onChange={(e, newValue) => changeProperty(item, "key", newValue)}
        onInputChange={(e) => e?.target?.value ? changeProperty(item, "key", e.target.value) : null}
      />
      <TextField disabled={item.fieldType == "ParentOIds" || item.fieldType == "AssignedUsers"} label={item.required ? "Name*" : "Name"} size="small" value={item.columnName} style={{flex: 1}} onChange={(e) => changeProperty(item, "columnName", e.target.value)}></TextField>
      <Select size="small" disabled={!item.isNew || item.fieldType == "ParentOIds"} value={item.fieldType} style={{flex: 1}} onChange={(e) => changeProperty(item, "fieldType", e.target.value)}>
        {Object.entries(groupedDataTypes).map(([key, innerDataTypes]) => {
          let items = [];
          items.push(<ListSubheader>{key}</ListSubheader>);
          Object.entries(innerDataTypes).map(([key, value]) => {
            let allowedFieldTypes = ["ParentOIds", "Location", "Map", "AssignedUsers"];
            let alreadyExisting = configItems?.findIndex((i) => i.fieldType == key && allowedFieldTypes.includes(i.fieldType));
            items.push(<MenuItem disabled={alreadyExisting > -1} value={key}>{value}</MenuItem>);  
          })
          return(items);
        })}
      </Select>
      {
        item.fieldType === "Bool" ? 
        <div style={{flex: 1, textAlign: "center"}}><Checkbox checked={item.defaultValue == "true"} size="small" onChange={(e) => changeProperty(item, "defaultValue", e.target.checked.toString())}></Checkbox></div>        

        : item.fieldType === "ParentOIds" || 
          item.fieldType === "AssignedUsers" || 
          item.fieldType === "Location" || 
          item.fieldType === "Map" || 
          item.fieldType === "File" ||
          item.fieldType === "Doc" ||
          item.fieldType === "Archive" ||
          item.fieldType === "Image" ? 
          <div style={{flex: 1}}/>

        : item.fieldType === "SingleList" ? 
          <Autocomplete
            id="free-solo-demo"
            size="small"
            style={{flex: 1}}
            value={item.defaultValue}
            options={configurationsJson?.listItems?.map((item) => ({id: item.key, label: item?.value ?? "Unknown"})) ?? []}
            renderInput={(params) => <TextField {...params} label="Default" />}
            isOptionEqualToValue={(option, value) => option.id == value.id}
            onChange={(option, value) => {
              changeProperty(item, "defaultValue", value?.id);
            }}
          />

        : item.fieldType === "MultiList" ? 
          <Autocomplete
            id="free-solo-demo"
            size="small"
            multiple
            style={{flex: 1, height: 40}}
            value={configurationsJson?.listItems?.map((listItem) => ({id: listItem.key, label: listItem?.value ?? "Unknown"})).filter((i) => JSON.parse(item?.defaultValue)?.includes(i.id)) ?? []}
            options={configurationsJson?.listItems?.map((listItem) => ({id: listItem.key, label: listItem?.value ?? "Unknown"})) ?? []}
            renderInput={(params) => <TextField {...params} label="Default" />}
            onChange={(option, values) => {
              changeProperty(item, "defaultValue", JSON.stringify(values.map((i) => i.id)));
            }}
          />

        : item.fieldType === "NestedTable" ? 
          <Autocomplete
            id="free-solo-demo"
            size="small"
            fullWidth
            disabled={!item.isNew}
            style={{flex: 1}}
            value={tables?.map((table) => ({id: table?.id, label: table?.tableName ?? "Unknown"})).find((table) => table?.id == configurationsJson?.tableId) ?? null}
            options={tables?.map((table) => ({id: table?.id, label: table?.tableName ?? "Unknown"})) ?? []}
            renderInput={(params) => <TextField {...params} label="Nested Table" />}
            onChange={(option, value) => {
              let config = {
                tableId: value?.id,
                type: "list",
                columnsToShow: []
              }
              changeProperty(item, "key", "nestedTable" + value?.id)
              changeProperty(item, "columnName", value?.label)
              changeProperty(item, "configurationsJson", JSON.stringify(config));
            }}
          />

        : <TextField type={item.fieldType === "Int" || item.fieldType === "Float" ? "number" : item.fieldType === "Date" ? "date" : item.fieldType === "DateTime" ? "datetime-local" : "text"} label="Default" size="small" value={item.defaultValue} style={{flex: 1}} onChange={(e) => changeProperty(item, "defaultValue", e.target.value)}></TextField>
      }
      <IconButton disabled={item.fieldType == "ParentOIds"} onClick={() => {
        setColumnToEdit(item)
        setTableDetailsOpen(true);
      }}><Tune/></IconButton>
      <IconButton disabled={item.fieldType == "ParentOIds"} onClick={() => removeItem(item)}><Delete/></IconButton>
    </div>
  )
}

function RenderColumnList({setTableDetailsOpen, configItems, availabeColumns, changeProperty, changeOpenProperty, removeItem, setColumnToEdit, tables, onDragEnd}) {

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    padding: "4px 0px 4px 0px",
    background: isDragging ? null : null,
    ...draggableStyle
  });

  const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? null : null,
  });

  return(
    <DragDropContext onDragEnd={onDragEnd}>
      {/* Outer Droppable */}
      <Droppable droppableId="outerDroppable" type="outerItem">
        {(outerProvided, outerSnapshot) => (
          <div ref={outerProvided.innerRef} style={getListStyle(outerSnapshot.isDraggingOver)}>
            {configItems?.map((item, index) => (
              <Draggable key={item.type + item.id} draggableId={"outerItem" + item.type + item.id} index={index}>
                {(outerDraggableProvided, outerDraggableSnapshot) => (
                  <div
                    ref={outerDraggableProvided.innerRef}
                    {...outerDraggableProvided.draggableProps}
                    style={getItemStyle(outerDraggableSnapshot.isDragging, outerDraggableProvided.draggableProps.style)}
                  >
                    <div {...outerDraggableProvided.dragHandleProps}>
                      {/* Render outer item (e.g., a column or sector) */}
                      {item.type === "column" ? (
                        <div style={{ paddingInline: 4 }}>
                          <RenderColumnItem
                            item={item}
                            idx={index}
                            availabeColumns={availabeColumns}
                            changeProperty={changeProperty}
                            removeItem={removeItem}
                            setColumnToEdit={setColumnToEdit}
                            setTableDetailsOpen={setTableDetailsOpen}
                            tables={tables}
                            configItems={configItems}
                          />
                        </div>
                      ) : (
                        <div style={{ backgroundColor: "#ddd", borderRadius: 12, paddingInline: 4, paddingBlock: 8 }}>
                          <div style={{ display: "flex", gap: "5px" }}>
                            <DragIndicator style={{ alignSelf: "center" }} />
                            <TextField
                              label="Key"
                              size="small"
                              value={item.sectorKey}
                              style={{ flex: 1 }}
                              onChange={(e) => changeProperty(item, "sectorKey", e.target.value)}
                            />
                            <TextField
                              label="Name"
                              size="small"
                              value={item.sectorName}
                              style={{ flex: 1 }}
                              onChange={(e) => changeProperty(item, "sectorName", e.target.value)}
                            />
                            <div style={{flex: 1}}/>
                            <div style={{flex: 1, textAlign: "center"}}>
                              <Checkbox
                                checked={item.defaultValue}
                                size="small"
                                style={{ flex: 1 }}
                                onChange={(e) => changeProperty(item, "defaultValue", e.target.checked)}
                              />
                            </div>
                            <IconButton onClick={() => changeOpenProperty(item.sectorKey, !item?.isOpen)}>{item?.isOpen ? <ExpandLess/> : <ExpandMore/>}</IconButton>
                            <IconButton onClick={() => removeItem(item)}><Delete /></IconButton>
                          </div>

                          {/* Inner Droppable for nested items */}
                          <Droppable droppableId={`innerDroppable_${item.id}`} type="innerItem">
                            {(innerProvided, innerSnapshot) => (
                              <div ref={innerProvided.innerRef} style={getListStyle(innerSnapshot.isDraggingOver)}>
                                {item?.columns?.length > 0 && item?.isOpen ?
                                  <div style={{marginTop: 8}}>
                                    {item?.columns?.map((column, innerIdx) => (
                                      <Draggable key={column.id} draggableId={"innerItem" + column.id} index={innerIdx}>
                                        {(innerDraggableProvided, innerDraggableSnapshot) => (
                                          <div
                                            ref={innerDraggableProvided.innerRef}
                                            {...innerDraggableProvided.draggableProps}
                                            {...innerDraggableProvided.dragHandleProps}
                                            style={getItemStyle(innerDraggableSnapshot.isDragging, innerDraggableProvided.draggableProps.style)}
                                          >
                                            {/*Render Columns inside Sector*/}
                                            <RenderColumnItem
                                              sectorItem={true}
                                              item={column}
                                              idx={innerIdx}
                                              changeProperty={changeProperty}
                                              removeItem={removeItem}
                                              setColumnToEdit={setColumnToEdit}
                                              setTableDetailsOpen={setTableDetailsOpen}
                                              tables={tables}
                                              configItems={configItems}
                                            />
                                          </div>
                                        )}
                                      </Draggable>
                                    ))}
                                  </div> : null
                                }
                                {innerProvided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {outerProvided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}


function TableConfiguration(props) {
  const theme = useTheme();
  const api = useApi();
  const [selectedCfg, setSelectedCfg] = useState(null);
  const [selectedBgColor, setSelectedBgColor] = useState(null);
  const [selectedIcon, setSelectedIcon] = useState(null);

  const [tableToDelete, setTableToDelete] = useState(null);

  const [iconSelectOpen, setIconSelectOpen] = useState(false);
  const [tableDetailsOpen, setTableDetailsOpen] = useState(false);
  const [columnToEdit, setColumnToEdit] = useState(null);

  const [codeViewColumn, setCodeViewColumn] = useState(false);

  const { cfgs, setCfgs, setTables, setStatusMsg } = useServerData();


  const location = useLocation();
  const [debugMode, setDebugMode] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const debug = params.get("debug") === "true"; // Check if debug=true

    console.log("debug", debug)

    setDebugMode(debug);
  }, [location.search]);


  const [availabeColumns, setAvailabeColumns] = useState(null);
  useEffect(() => {
    let newCols = [];
    cfgs.map((cfg) => {
      cfg.columns.map((col) => {
        if(!newCols.includes(col.key)) newCols.push(col.key)
      })
    })
    setAvailabeColumns(newCols)
  }, [cfgs]);

  const [processing, isProcessing] = useState(false);


  //Add Menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  

  useEffect(() => {
    setSelectedBgColor(selectedCfg?.tableIconBgColor)
  }, [selectedCfg]);

  async function handleIconSave() {
    if(selectedIcon == null) {
      let newSelectedTable = {...selectedCfg};
      newSelectedTable.tableIconBgColor = selectedBgColor;
      setSelectedCfg(newSelectedTable);
      setIconSelectOpen(false);
      return;
    }

    fetch(selectedIcon)
    .then(response => response.blob())
    .then(async (blob) => {
      svgToPng(blob, selectedBgColor)
      .then(async (pngBlob) => {
        let iconBase64 = await toBase64(pngBlob);
        let newSelectedTable = {...selectedCfg};
        newSelectedTable.tableIcon = iconBase64;
        newSelectedTable.tableIconBgColor = selectedBgColor;
        setSelectedCfg(newSelectedTable);
        setIconSelectOpen(false);
      })
      .catch(console.error);
    });
  }


  function tablePOSTPUT() {
    isProcessing(true);

    let fields = [];
    if(selectedCfg?.uniqueKey == null || selectedCfg?.uniqueKey == "") {
      fields.push("Table Key")
    }
    if(selectedCfg?.tableName == null || selectedCfg?.tableName == "") {
      fields.push("Table Name")
    }

    if(fields.length > 0) {
      setStatusMsg({
        type: "Error",
        message: "Please fill in the following required fields: " + fields.join(", ")
      })
      return;
    }


    let tableIsNew = selectedCfg?.isNew ?? false;

    selectedCfg.sectors = [];
    selectedCfg.columns = [];

    let newConfigItems = JSON.parse(JSON.stringify(configItems));

    console.log(newConfigItems)

    let order = 0;

    newConfigItems.map((item, idx) => {
      if(!item.mobileUIConfig) {
        item.mobileUIConfig = {};
      }
      if(!item.webUIConfig) {
        item.webUIConfig = {};
      }
      item.mobileUIConfig.order = order;
      item.webUIConfig.order = order;
      order += 1;

      if(item.type == "sector") {
        item.columns?.map((col) => {
          col.mobileUIConfig.sectorKey = item.sectorKey;
          //delete col.mobileUIConfig.sectorId;

          col.mobileUIConfig.order = order;
          col.webUIConfig.order = order;
          order += 1;

          delete col.type;
          if(col.isNew) {
            delete col.isNew;
            delete col.id;
          }
          selectedCfg.columns.push(col);
        })

        delete item.columns;
        delete item.type;
        if(item.isNew) {
          delete item.isNew;
          delete item.id;
        }
        selectedCfg.sectors.push(item)
      } else {
        //delete item.mobileUIConfig.sectorId;

        delete item.type;
        if(item.isNew) {
          delete item.isNew;
          delete item.id;
        }
        selectedCfg.columns.push(item);
      }
    })


    let url = GATEWAY_URL + ADMIN_URL + "/api/Tables" + (tableIsNew ? "" : "/" + selectedCfg?.id);

    console.log(url, selectedCfg)

    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: tableIsNew ? "POST" : "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: JSON.stringify(selectedCfg)
    };

    api.customRequest(url, requestOptions, async (response) => {
      if(response.status < 300) {
        setStatusMsg({
          type: "Success",
          message: "Table saved successfully!"
        });
        setTables();
      } else {
        setStatusMsg({
          type: "Error",
          message: await response.text()
        })
      }
      isProcessing(false);
    });
  }

  function tableDELETE(table) {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + props.bearerToken);
    myHeaders.append("Content-Type", "application/json");

    const requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow"
    };

    let url = GATEWAY_URL + ADMIN_URL + "/api/Tables" + "/" + table?.id;
    api.customRequest(url, requestOptions, async (response) => {
      if(response.status < 300) {
        setStatusMsg({
          type: "Success",
          message: "Table removed successfully!"
        });
        setTables();
      } else {
        setStatusMsg({
          type: "Error",
          message: await response.text()
        })
      }
      setTableToDelete(null);
    });
  }

  function createNewTable() {
    let newTables = [...cfgs];
    let newTable = {
      uniqueKey: "",
      tableName: "New Table",
      sectors: [
      ],
      columns: [
        {
          id: uuidv4(),
          key: "parentOids",
          columnName: "parentOids",
          fieldType: "ParentOIds",
          configurationsJson: "",
          mobileUIConfig: {
            order: 0,
            required: false
          },
          webUIConfig: {
            editable: false,
            order: 0
          },
          isNew: true,
          type: "column",
          defaultValue: ""
        }
      ],
      tableIconBgColor: "#eb4034",
      tableIcon: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAstJREFUeF7tmV1S4kAYRbthBVNYEd50JTPuRFairgR3oq5k8gampFxBaCtFWSVTSTr9Y+4Qj6+k+5Jzvgt0tIY/KQErTSfcIEA8BAhAgJiAOJ4GIEBMQBxPAxAgJiCOpwEIEBMQx9MABIgJiONpAALEBMTxNAABYgLieBqAADEBcTwNQICYgDieBiBATEAcTwMQICYgjqcBCBATEMfTAASICYjjaQACxATE8TQAAWIC4ngagAAxAXE8DUDAKYFdtXdtTJbFonNYttv3q9n8cOeMuY3gWRrjSlfP16vVrzJifdKS/64BoQIa+HZ++JtE4bi4dPXsZmwJZy/gtdpvIie/xZl7XhYXNxlkDt7i7AXsqn0z/VeD77j/wnJZLK4z7TVomykIaP3OGHT3LRf1fdfE7tm3DgH/0EFA4K+g0C/tT96x63K3YLIN8E0yAjpGKRRM6PW5Jzh1vx/bgFRwudZPQUDqz1BOwl+nKfQjZVe9PRlj/2SYSE7CDcRQARkfRRhjOAkHC2ikHSXUmwxNmN5JOHSiQ6+P/egZK8f3/r79Szj0RkOv991g1+tj5fje39kLiAUZu84HNPR1BEz9WVCux8Vdjxa6Jjl0Ej+v9z3CiN23a90IDUj/nW6NebwsFuu2m0CAZyS21dutNXaTMjnOuPWquHjsEJB6Ej7ZdnINOB6uUlrQfzhK2/tUaV/TUgaob+23fwSlHZT8J9OcJ2FXz64n/U/57ev+3lr323NiPT4cc/Zldbm4HzJ5iSfh0hrzfKhnD2PDb+5tlAYMgfhTr0GA2DwCECAmII6nAQgQExDH0wAEiAmI42kAAsQExPE0AAFiAuJ4GoAAMQFxPA1AgJiAOJ4GIEBMQBxPAxAgJiCOpwEIEBMQx9MABIgJiONpAALEBMTxNAABYgLieBqAADEBcTwNQICYgDieBiBATEAcTwMQICYgjv8AW0YbcB1aT/wAAAAASUVORK5CYII=",
      isIndependent: true,
      isNew: true
    }
    newTables.push(newTable);
    setCfgs(newTables);
    setSelectedCfg(newTable);
  }


  function getNewIndex() {
    let cnt = 0;
    configItems.map((i) => {
      cnt += 1;
      console.log(i.columns?.length);
      cnt += i.columns?.length ?? 0;
    })
    
    console.log(cnt);
    return cnt + 1;
  }

  function createNewSector() {
    let newConfigItems = [...configItems];
    let newSector = {
      id: uuidv4(),
      sectorKey: "sector" + getNewIndex(),
      sectorName: "Sector " + getNewIndex(),
      defaultValue: false,
      mobileUIConfig: {
        order: getNewIndex()
      },
      isNew: true,
      type: "sector"
    }
    if(newSector.mobileUIConfig == null) {
      newSector.mobileUIConfig = {}
    }
    newConfigItems.push(newSector);
    setConfigItems(newConfigItems);
    handleClose();
  }

  function createNewColumn() {
    let newConfigItems = [...configItems];
    let newSector = {
      id: uuidv4(),
      key: "column" + getNewIndex(),
      columnName: "Column " + getNewIndex(),
      fieldType: "String",
      configurationsJson: "",
      required: false,
      mobileUIConfig: {
        visible: true,
        disabled: false,
        editable: true,
        order: getNewIndex(),
        extraConfigJson: ""
      },
      webUIConfig: {
        visible: true,
        disabled: false,
        editable: true,
        order: getNewIndex(),
        extraConfigJson: ""
      },
      isNew: true,
      type: "column"
    }
    if(newSector.mobileUIConfig == null) {
      newSector.mobileUIConfig = {}
    }
    newConfigItems.push(newSector);
    setConfigItems(newConfigItems);
    handleClose();
  }


  function changeTableProperty(key, value) {
    let newSelectedTable = {...selectedCfg};
    newSelectedTable[key] = value;
    setSelectedCfg(newSelectedTable);
  }

  function changeProperty(item, key, value) {
    let newConfigItems = [...configItems];

    let insideSector = item.mobileUIConfig?.sectorKey != null;
    if(insideSector) {
      let secIdx = newConfigItems.findIndex((sec) => sec?.sectorKey == item.mobileUIConfig?.sectorKey);
      let idx = newConfigItems[secIdx].columns.findIndex((i) => i.id == item.id);
      if(idx > -1) {
        newConfigItems[secIdx].columns[idx][key] = value; 

        if(key == "fieldType") {
          switch(value) {
            case "Bool":
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "false";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "";
              break;
            
            case "ParentOIds":
              newConfigItems[secIdx].columns[idx]["key"] = "parentOIds";
              break;

            case "Location":
              newConfigItems[secIdx].columns[idx]["key"] = "location";
              newConfigItems[secIdx].columns[idx]["columnName"] = "Location";
              break;

            case "Int":
            case "Float":
            case "String":
            case "MultilineText":
            case "Email":
            case "Href":
            case "File":
            case "Doc":
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "";
              break;

            case "Map":
              newConfigItems[secIdx].columns[idx]["key"] = "map";
              newConfigItems[secIdx].columns[idx]["columnName"] = "Map";
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "";
              break;
    
            case "SingleList":
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "{\"listItems\": [{\"key\": \"key1\", \"value\": \"Eins\", \"color\": \"#ddd\"}, {\"key\": \"key2\", \"value\": \"Zwei\", \"color\": \"#090\"}]}";
              break;
    
            case "MultiList":
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "[]";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "{\"listItems\": [{\"key\": \"key1\", \"value\": \"Eins\", \"color\": \"#ddd\"}, {\"key\": \"key2\", \"value\": \"Zwei\", \"color\": \"#090\"}]}";
              break;
            
            case "DynamicList":
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "[]";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "{\"tableId\": \"tableIdInteger\", \"labelTemplate\": \"${value}\"}";
              break;

            case "AssignedUsers":
              newConfigItems[secIdx].columns[idx]["key"] = "assignedUsers";
              newConfigItems[secIdx].columns[idx]["columnName"] = "Zugewiesen";
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "";
              break;

            case "Archive":
              newConfigItems[idx]["key"] = "archive";
              newConfigItems[idx]["columnName"] = "Archiv";
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;
    
            default: 
              newConfigItems[secIdx].columns[idx]["defaultValue"] = "";
              newConfigItems[secIdx].columns[idx]["configurationsJson"] = "";
              break;
          }
        }
      }
    } else {
      let idx = newConfigItems.findIndex((i) => i.id == item.id);
      if(idx > -1) {
        newConfigItems[idx][key] = value; 

        if(key == "fieldType") {
          switch(value) {
            case "Bool":
              newConfigItems[idx]["defaultValue"] = "false";
              newConfigItems[idx]["configurationsJson"] = "";
              break;
            
            case "ParentOIds":
              newConfigItems[idx]["key"] = "parentOIds";
              break;

            case "Location":
              newConfigItems[idx]["key"] = "location";
              newConfigItems[idx]["columnName"] = "Location";
              break;

            case "Int":
            case "Float":
            case "String":
            case "MultilineText":
            case "Email":
            case "Href":
            case "File":
            case "Doc":
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;

            case "Map":
              newConfigItems[idx]["key"] = "map";
              newConfigItems[idx]["columnName"] = "Map";
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;
    
            case "SingleList":
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "{\"listItems\": [{\"key\": \"key1\", \"value\": \"Eins\", \"color\": \"#ddd\"}, {\"key\": \"key2\", \"value\": \"Zwei\", \"color\": \"#090\"}]}";
              break;
    
            case "MultiList":
              newConfigItems[idx]["defaultValue"] = "[]";
              newConfigItems[idx]["configurationsJson"] = "{\"listItems\": [{\"key\": \"key1\", \"value\": \"Eins\", \"color\": \"#ddd\"}, {\"key\": \"key2\", \"value\": \"Zwei\", \"color\": \"#090\"}]}";
              break;
            
            case "DynamicList":
              newConfigItems[idx]["defaultValue"] = "[]";
              newConfigItems[idx]["configurationsJson"] = "{\"tableId\": \"tableIdInteger\", \"labelTemplate\": \"${value}\"}";
              break;

            case "AssignedUsers":
              newConfigItems[idx]["key"] = "assignedUsers";
              newConfigItems[idx]["columnName"] = "Zugewiesen";
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;

            case "Archive":
              newConfigItems[idx]["key"] = "archive";
              newConfigItems[idx]["columnName"] = "Archiv";
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;
    
            default: 
              newConfigItems[idx]["defaultValue"] = "";
              newConfigItems[idx]["configurationsJson"] = "";
              break;
          }
        }
      }
    }
    setConfigItems(newConfigItems);
  }

  function changeOpenProperty(sectorKey, open) {
    let newConfigItems = [...configItems];
    let idx = newConfigItems.findIndex((i) => i.sectorKey == sectorKey);
    newConfigItems[idx].isOpen = open; 
    setConfigItems(newConfigItems);
  }

  //Drag n Drop
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    if(result.type == "outerItem") {
      const newItems = reorder(configItems, result.source.index, result.destination.index);
      setConfigItems(newItems);
    } else {
      let sectorId = result.source.droppableId.split("_")[1];
      let idx = configItems.findIndex((i) => i.id == sectorId);
      if(idx > -1) {
        const newItems = [...reorder(configItems[idx].columns, result.source.index, result.destination.index)];
        configItems[idx].columns = newItems;
        setConfigItems([...configItems]);
      }
    }
  }

  function changeMobileConfig(item, key, value) {
    let newConfigItems = [...configItems];
    console.log(item, key, value)
    let insideSector = item.mobileUIConfig?.sectorKey != null;

    console.log(insideSector)

    let cols = [];
    let newCfgItems = [];
    newConfigItems.map((item) => {
      cols.push(item);
      if(item?.type == "sector" && item?.columns) {
        cols = cols.concat(item?.columns);
      }
    })

    let idx = cols.findIndex((i) => i.id == item.id);
    let newColumn = cols[idx];

    if(newColumn.mobileUIConfig == null) {
      newColumn.mobileUIConfig = {}
    }
    newColumn.mobileUIConfig[key] = value;
    cols[idx] = newColumn;

    cols?.forEach((item) => {
      if(item.type == "sector") {
        item.columns = cols?.filter((col) => col?.mobileUIConfig?.sectorKey === item.sectorKey);
        newCfgItems.push(item);
      } else {
        if((item?.mobileUIConfig?.sectorId == null && item?.mobileUIConfig?.sectorKey == null) || !cols?.filter((col) => col?.type == "sector").find((i) => item?.mobileUIConfig?.sectorKey === i.sectorKey)) {
          newCfgItems.push(item);
        }
      }
    })
    newConfigItems = [...newCfgItems];
    

    setConfigItems(newConfigItems);
  }

  function changeWebConfig(item, key, value) {
    let newConfigItems = [...configItems];
    console.log(item, key, value)
    let insideSector = item.mobileUIConfig?.sectorKey != null;
    if(insideSector) {
      let secIdx = newConfigItems.findIndex((sec) => sec?.sectorKey == item.mobileUIConfig?.sectorKey);
      let idx = newConfigItems[secIdx].columns.findIndex((i) => i.id == item.id);
      if(idx > -1) {
        if(newConfigItems[secIdx].columns[idx].webUIConfig == null) {
          newConfigItems[secIdx].columns[idx].webUIConfig = {};
        }
        newConfigItems[secIdx].columns[idx].webUIConfig[key] = value;
      }
    } else {
      let idx = newConfigItems.findIndex((i) => i.id == item.id);
      if(idx > -1) {
        if(newConfigItems[idx].webUIConfig == null) {
          newConfigItems[idx].webUIConfig = {};
        }
        newConfigItems[idx].webUIConfig[key] = value;
      }
    }
    setConfigItems(newConfigItems);
  }

  function removeItem(item) {
    let newConfigItems = [...configItems];
    let insideSector = item.mobileUIConfig?.sectorKey != null;
    if(insideSector) {
      let secIdx = newConfigItems.findIndex((sec) => sec?.sectorKey == item.mobileUIConfig?.sectorKey);
      let idx = newConfigItems[secIdx].columns.findIndex((i) => i.id == item.id && i.type == item.type);
      newConfigItems[secIdx].columns.splice(idx, 1);
    } else {
      let idx = newConfigItems.findIndex((i) => i.id == item.id && i.type == item.type);
      newConfigItems.splice(idx, 1);
    }
    setConfigItems(newConfigItems);
  }

  useEffect(() => {
    let newSelectedCfg = cfgs?.find((i) => i.uniqueKey == selectedCfg?.uniqueKey);
    setSelectedCfg(newSelectedCfg)
  }, [cfgs]);

  //ConfigItems for Configurator
  const [configItems, setConfigItems] = useState();
  function rearange() {
    let newItems = [];
    let tmpTable = {...selectedCfg};
    tmpTable?.sectors?.forEach((sec) => {
      sec.type = "sector";
      sec.columns = selectedCfg?.columns?.filter((col) => col?.mobileUIConfig?.sectorKey === sec.sectorKey);
      sec.columns.sort((a, b) => a?.mobileUIConfig?.order - b?.mobileUIConfig?.order).map((col) => {
        col.type = "column";
      })
      sec.isOpen = true;
      newItems.push(sec)
    })
    tmpTable?.columns?.filter((i) => i?.mobileUIConfig?.sectorKey == null || !tmpTable.sectors?.find((j) => j.sectorKey == i?.mobileUIConfig?.sectorKey)).forEach((col) => {
      col.type = "column";
      newItems.push(col)
    })
    newItems = newItems.sort((a, b) => a?.mobileUIConfig?.order - b?.mobileUIConfig?.order);
    setConfigItems(newItems);
  }
  useEffect(() => {
    rearange();
  }, [selectedCfg]);

  return (
    <div style={{ display: "flex", backgroundColor: theme.palette.background.default, flexDirection: "column", width: "100%", height: "calc(100vh)", overflow: "hidden" }}>
      <div style={{margin: "30px", display: "flex", flexDirection: "column", gap: "20px"}}>
        <Typography fontSize={30} fontWeight={600} color={theme.palette.text.primary}>Table Configurator {debugMode ? " - DEBUG" : null}</Typography>
        <div style={{display: "flex", gap: "20px"}}> 
          <Autocomplete
            fullWidth
            size="small"
            value={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"})).find((i) => i.id == selectedCfg?.id) ?? null}
            options={cfgs?.map((table) => ({id: table.id, label: table?.tableName ?? "Unknown"}))}

            renderInput={(params) => <TextField {...params} label="Tables" />}
            onChange={(option, value) => {
              let selectedTable = cfgs.find((table) => table.id === (value != null ? value.id : null));
              if(selectedTable != null) {
                delete selectedTable["folder"];
              }
              setSelectedCfg(selectedTable);
            }}
          />
          <IconButton key={"deletebutton"} disabled={!selectedCfg} color="error" onClick={() => setTableToDelete(selectedCfg)}><DeleteForever/></IconButton>
          <IconButton key={"addbutton"} onClick={() => createNewTable()}><Add/></IconButton>
          <IconButton key={"savebutton"} disabled={!selectedCfg} onClick={() => tablePOSTPUT()}>{!processing ? <Save/> : <CircularProgress size={"24px"}/>}</IconButton>
        </div>

        <div style={{display: "flex"}}>
          <Box style={{display: "flex", flexDirection: "column", width: "50%", gap: "16px", padding: "10px"}}>
            <Typography fontSize={18} fontWeight={600} color={theme.palette.text.primary}>Configuration - ID {selectedCfg?.id}</Typography>
            <TextField disabled={!selectedCfg} label="Key" size="small" value={selectedCfg?.uniqueKey ?? ""} onChange={(e) => changeTableProperty("uniqueKey", e.target.value)}></TextField>
            <div style={{display: "flex", gap: 10}}>
              <ButtonBase disabled={!selectedCfg} component="div" onClick={() => setIconSelectOpen(true)} style={{height: "40px", width: "40px", backgroundColor: selectedCfg?.tableIconBgColor ?? "#090", borderRadius: 8, flexShrink: 0}}>
                {selectedCfg?.tableIcon ? <img height="30px" src={selectedCfg?.tableIcon}></img> : <Add/>}
              </ButtonBase>
              <TextField disabled={!selectedCfg} fullWidth label="Name" size="small" value={selectedCfg && selectedCfg.tableName ? selectedCfg.tableName : ""}  onChange={(e) => changeTableProperty("tableName", e.target.value)}></TextField>
              <IconButton onClick={() => setTableDetailsOpen(true)}><Tune/></IconButton>
            </div>

            <div style={{display: "flex", gap: 10}}>
              <TextField disabled={!selectedCfg} fullWidth label="Header Label" size="small" value={selectedCfg && selectedCfg.headerLabelTemplate ? selectedCfg.headerLabelTemplate : ""}  onChange={(e) => changeTableProperty("headerLabelTemplate", e.target.value)}></TextField>
              <TextField disabled={!selectedCfg}fullWidth label="Details Label" size="small" value={selectedCfg && selectedCfg.detailsLabelTemplate ? selectedCfg.detailsLabelTemplate : ""}  onChange={(e) => changeTableProperty("detailsLabelTemplate", e.target.value)}></TextField>
              <TextField disabled={!selectedCfg} fullWidth label="Icon Label" size="small" value={selectedCfg && selectedCfg.iconLabelTemplate ? selectedCfg.iconLabelTemplate : ""}  onChange={(e) => changeTableProperty("iconLabelTemplate", e.target.value)}></TextField>
              <TextField disabled={!selectedCfg} fullWidth label="Map Label" size="small" value={selectedCfg && selectedCfg.mapLabelTemplate ? selectedCfg.mapLabelTemplate : ""}  onChange={(e) => changeTableProperty("mapLabelTemplate", e.target.value)}></TextField>
            </div>

            <div style={{display: "flex", gap: 10}}>
              <TextField disabled={!selectedCfg}fullWidth label="Admin Folder Path" size="small" value={selectedCfg && selectedCfg.adminPath ? selectedCfg.adminPath : ""}  onChange={(e) => changeTableProperty("adminPath", e.target.value)}></TextField>
              <TextField disabled={!selectedCfg} fullWidth label="Company Folder Path" size="small" value={selectedCfg && selectedCfg.companyPath ? selectedCfg.companyPath : ""}  onChange={(e) => changeTableProperty("companyPath", e.target.value)}></TextField>
            </div>

            <div style={{display: "flex"}}>
              <Typography fontSize={18} fontWeight={600} color={theme.palette.text.primary} style={{alignContent: "center"}}>Configuration</Typography>
              <IconButton onClick={() => setCodeViewColumn(!codeViewColumn)}>{codeViewColumn ? <Code/> : <CodeOff/>}</IconButton>
            </div>

            <div style={{display: "flex", flexDirection: "column", gap: "8px", padding: "10px", borderRadius: "4px", border: "solid #ccc 1px", overflowY: "scroll", height: "50vh", justifyContent: "flex-start"}}>
              <RenderColumnList
                availabeColumns={availabeColumns}
                configItems={configItems}
                selectedTable={selectedCfg}
                changeProperty={changeProperty}
                changeOpenProperty={changeOpenProperty}
                removeItem={removeItem}
                setColumnToEdit={setColumnToEdit}
                tables={cfgs}
                setConfigItems={setConfigItems}
                setTableDetailsOpen={setTableDetailsOpen}
                onDragEnd={onDragEnd}
              />
              <IconButton disabled={!selectedCfg} style={{height: "48px", width: "48px", alignSelf: "center"}} onClick={handleClick}><Add/></IconButton>  
              <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose} MenuListProps={{'aria-labelledby': 'basic-button'}}>
                <MenuItem onClick={() => createNewColumn()}>Create Column</MenuItem>
                <MenuItem onClick={() => createNewSector()}>Create Sector</MenuItem>
              </Menu>
            </div>
          </Box>

          <Box style={{display: "flex", flexDirection: "column", width: "50%", gap: "20px", padding: "10px"}}>
            <Editor height={"80vh"} language="json" value={JSON.stringify(selectedCfg ?? "", null, 2)}/>
          </Box>
        </div>
      </div>

      <Dialog open={iconSelectOpen}>
        <DialogTitle id="alert-dialog-title">Icon Configurator</DialogTitle>
        <DialogContent>
          <div style={{display: "flex", gap: 16}}>
            <div style={{display: "flex", flexDirection: "column", gap: 8}}> 
              <Typography>Select Icon</Typography>
              <div style={{display: "flex", flexWrap: "wrap"}}>
                {tableIcons.map((tableIcon) => 
                  <IconButton onClick={() => setSelectedIcon(tableIcon)} style={{height: "36px", width: "36px", backgroundColor: selectedCfg === tableIcon ? "grey" : null}}>
                    <img height="30px" src={tableIcon} style={{filter: "invert(1)"}}></img>
                  </IconButton>
                )}
              </div>
              <Typography>Select Background Color</Typography>
              <MuiColorInput format="hex" size="small" fullWidth value={selectedBgColor} onChange={(customColor) => setSelectedBgColor(customColor)}></MuiColorInput>
            </div>
            <div style={{display: "flex", flexDirection: "column", gap: 8}}>
              <Typography>Preview</Typography>
              <ButtonBase component="div" onClick={() => setIconSelectOpen(true)} style={{height: "64px", width: "64px", backgroundColor: selectedBgColor, borderRadius: 12, flexShrink: 0}}>
                {selectedCfg?.tableIcon ? <img height="36px" src={selectedIcon ?? selectedCfg.tableIcon} style={{filter: getShouldInvertFromHex(selectedBgColor) ? "invert(1)" : null}}></img> : <Add/>}
              </ButtonBase>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="text" onClick={() => setIconSelectOpen(false)}>Schließen</Button>
          <Button variant="contained" onClick={() => handleIconSave()} autoFocus>Speichern</Button>
        </DialogActions>
      </Dialog>

      <DetailsDialog
        debugMode={debugMode}

        selectedCfg={selectedCfg}
        tableDetailsOpen={tableDetailsOpen}
        changeTableProperty={changeTableProperty}
        setTableDetailsOpen={setTableDetailsOpen}

        columnToEdit={columnToEdit}
        configItems={configItems}
        changeProperty={changeProperty}
        changeMobileConfig={changeMobileConfig}
        changeWebConfig={changeWebConfig}
        setColumnToEdit={setColumnToEdit}
      />

      <Dialog open={tableToDelete != null}>
        <DialogTitle id="alert-dialog-title">
          <div style={{display: "flex", gap: 8}}>
            <WarningAmberOutlined style={{alignSelf: "center"}} color='error'/>
            <Typography fontSize={20}>Delete Table</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sind Sie sicher, dass Sie die ausgewählte Tabelle löschen möchten?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button style={{ color: "grey", width: "115px" }} onClick={() => setTableToDelete(null)}>
            Abbrechen
          </Button>
          <TimedButton
            style={{ width: "115px" }}
            color={"error"}
            variant="contained"
            onClick={() => { tableDELETE(tableToDelete) }}
            autoFocus
            timeout={10}
          >
            Löschen
          </TimedButton>
        </DialogActions>
      </Dialog>

    </div>
  );
}

export default TableConfiguration;