import React, { useState } from "react";
import GridLayout from "react-grid-layout";
import { Container, Card, CardContent, Typography, Box } from "@mui/material";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import { BarChart } from "@mui/x-charts";
import { useAuth } from "../../providers/AuthProvider";
import { useApi } from "../../hooks/useApi";
import { useServerData } from "../../providers/DataProvider";

const generateBarChartConfig = (entries, userState, customRequest, width, height) => {

  let data = Object.fromEntries(Object.entries(entries).map(([key, value]) => [key, value.length]));

  return {
    xAxis: [{ scaleType: "band", data: Object.keys(data) }],
    series: [
      { data: Object.values(data) }
    ],
    width: width,
    height: height
  };
};

const Widget = ({ config, entries, userState, customRequest, width, height }) => {
  const chartConfig = config.getChartConfig(entries, userState, customRequest, width, height);
  return (
    <Card style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100%" }}>
      <CardContent>
        <Typography variant="h6" className="drag-handle">
          {config.title}
        </Typography>
        <BarChart {...chartConfig}/>
      </CardContent>
    </Card>
  );
};

function DashboardView(props) {
  const { userState } = useAuth();
  const { customRequest } = useApi();
  const { dataEntries } = useServerData();

  const [layout, setLayout] = useState([
    { i: "1", x: 0, y: 0, w: 3, h: 2 },
    { i: "2", x: 3, y: 0, w: 3, h: 2 },
    { i: "3", x: 6, y: 0, w: 3, h: 2 },
  ]);

  const widgets = {
    "1": { title: "Sales Data", getChartConfig: generateBarChartConfig },
    "2": { title: "Revenue Growth", getChartConfig: generateBarChartConfig },
    "3": { title: "Customer Trends", getChartConfig: generateBarChartConfig },
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Dashboard Builder
      </Typography>
      <GridLayout
        className="layout"
        layout={layout}
        cols={12}
        rowHeight={100}
        width={1200}
        onLayoutChange={(newLayout) => setLayout(newLayout)}
        draggableHandle=".drag-handle"
        isDraggable={true}
        isResizable={true}
      >
        {layout.map((item) => (
          <div key={item.i} data-grid={item} style={{ width: "100%", height: "100%" }}>
            <Widget
              config={widgets[item.i]}
              entries={dataEntries}
              userState={userState}
              customRequest={customRequest}
              width={item.w * 100}
              height={item.h * 100}
            />
          </div>
        ))}
      </GridLayout>
    </Container>
  );
}

export default DashboardView;